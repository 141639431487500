import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { pais } from './../config'

export default function ProductHomeCard({ data, actualPage = 1 }) {

  const [productNameUrl, setProductNameUrl] = useState()

  useEffect(() => {
    let newProductName = data.nombre.replace(/\//g, '-')
    setProductNameUrl(newProductName)
  }, [])
  if(pais === 'AR'){

    return (
        <Link
            className="relative flex-shrink-0 rounded-[5px] w-[50%] sm:w-[32%] lg:w-[220px] text-center group border-1 border-white sm:border-black hover:border-gray-02 duration-200"
            to={`/producto/${encodeURIComponent(productNameUrl)}/${data.productoid}`}
        >
          <p className="sm:absolute bg-black text-blue sm:h-[34px] font-bold px-4 py-2 top-0 left-2 rounded-b-[5px] sm:pb-[2em] sm:pt-4 text-[14px] group-hover:text-white duration-200">
            ${data?.preciopromo !== "0.00" ? data?.preciopromo : data?.precioventa }
          </p>
          {
            data.imagen === "sin imagen" ?
                <div className="h-[140px] sm:h-[190px] text-[18px] object-cover rounded-[5px] w-[100%] bg-blue text-white flex justify-center items-center">
                  <p className="p-3">{data.nombre.toUpperCase()}</p>
                  {
                      data.preciopromo !== "0.00" &&
                      <span
                          className="absolute bg-error text-white sm:h-[34px] font-bold px-4 py-2 bottom-0 right-0 sm:pb-[2em] text-[14px] group-hover:text-white duration-200">
            <span>OFERTA</span>
            </span>
                  }
                </div> :
                <div className="h-[140px] sm:h-[190px] text-[18px] object-cover rounded-[5px] w-[100%] bg-blue text-white flex justify-center items-center">
                  <img
                      className="h-[140px] sm:h-[190px] object-cover rounded-[5px] w-[100%]"
                      src={data.imagen}
                  />
                  {
                      data.preciopromo !== "0.00" &&
                      <span
                          className="absolute bg-error text-white sm:h-[34px] font-bold px-4 py-2 bottom-0 right-0 sm:pb-[2em] text-[14px] group-hover:text-white duration-200">
            <span>OFERTA</span>
            </span>
                  }
                </div>
          }
        </Link>
    )
  }else{

    return (
        <Link
            className="relative flex-shrink-0 rounded-[5px] w-[50%] sm:w-[32%] lg:w-[220px] text-center group border-1 border-white sm:border-black hover:border-gray-02 duration-200"
            to={`/producto/${encodeURIComponent(productNameUrl)}/${data.productoid}`}
        >
          <p className="sm:absolute bg-black text-blue sm:h-[34px] font-bold px-4 py-2 top-0 left-2 rounded-b-[5px] sm:pb-[2em] sm:pt-4 text-[14px] group-hover:text-white duration-200">
            US$ {data?.preciopromo !== "0.00" ? data?.preciopromo : data?.precioventa }
          </p>
          {
            data.imagen === "sin imagen" ?
                <div className="h-[140px] sm:h-[190px] text-[18px] object-cover rounded-[5px] w-[100%] bg-blue text-white flex justify-center items-center">
                  <p className="p-3">{data.nombre.toUpperCase()}</p>
                  {
                      data.preciopromo !== "0.00" &&
                      <span
                          className="absolute bg-error text-white sm:h-[34px] font-bold px-4 py-2 bottom-0 right-0 sm:pb-[2em] text-[14px] group-hover:text-white duration-200">
            <span>OFFER</span>
            </span>
                  }
                </div> :
                <div className="h-[140px] sm:h-[190px] text-[18px] object-cover rounded-[5px] w-[100%] bg-blue text-white flex justify-center items-center">
                  <img
                      className="h-[140px] sm:h-[190px] object-cover rounded-[5px] w-[100%]"
                      src={data.imagen}
                  />
                  {
                      data.preciopromo !== "0.00" &&
                      <span
                          className="absolute bg-error text-white sm:h-[34px] font-bold px-4 py-2 bottom-0 right-0 sm:pb-[2em] text-[14px] group-hover:text-white duration-200">
            <span>OFFER</span>
            </span>
                  }
                </div>
          }
        </Link>
    )
  }
}
