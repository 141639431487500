import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { pais } from './../config'
export default function ProductCard({ product }) {
  const [productNameUrl, setProductNameUrl] = useState()

  let colores = JSON.parse(localStorage.getItem('dataStore')) || ''

  const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
 }

  useEffect(() => {
    let newProductName = product.name.replace(/\//g, '-')
    setProductNameUrl(newProductName)
  }, [])
    if(pais === 'AR'){
        return (
            <Link
                key={product.id}
                className="relative flex flex-col border-1 border-white sm:border-black flex-shrink-0 sm:rounded-[5px] h-[300px] w-[50%] sm:w-[32%] lg:w-[24%] xl:w-[220px] text-center hover:shadow-2xl hover:border-gray-03 duration-200"
                to={`/producto/${encodeURIComponent(productNameUrl)}/${product.id}`}
            >
                {
                    product.image === "sin imagen" ?
                        <div
                            className="h-[150px] text-[20px] flex flex-grow w-[100%] bg-blue lex justify-center items-center text-white text-[18px]"
                        >
                            <p className="p-3">{product.name.toUpperCase()}</p>
                            {
                                product.precioPromo !== "0,00" &&
                                <span style={{width:139, borderTopRightRadius:3}}
                                      className="bg-error absolute top-0 text-white right-0">
          <span>OFERTA</span>
          </span>
                            }
                        </div> :
                        <div className="h-[150px] text-[20px] flex flex-grow w-[100%]"
                        >
                            <img
                                className="h-[190px] sm:h-[190px] object-cover rounded-[5px] w-[100%]"
                                src={product.image}
                            />
                            {
                                product.precioPromo !== "0,00" &&
                                <span style={{width:139, borderTopRightRadius:3}}
                                      className="bg-error absolute right-0 top-0 text-white">
          <span>OFERTA</span>
          </span>
                            }
                        </div>
                }
                <div style={{backgroundColor: colores.ecommerce_colorTerciario}}  className="flex flex-col items-start text-left text-blue p-3 h-[130px] justify-between">
                    <p className="text-[13px] text-left min-h-[45px]">
                        {product.name?.length > 30
                            ? product.name.slice(0, 30).toUpperCase() + '...'
                            : product.name?.toUpperCase()}
                    </p>
                    <div>
                        <p className={`font-bold text-[17px] US$ {product.precioPromo !== "0,00" && "line-through text-[15px]"}`}>${product.price}</p>
                        {product.precioPromo !== "0,00" && <p className="font-bold text-[17px]">US$ {product.precioPromo}</p>}
                        {
                            product.es_digital === "0" && product.stock !== 0 ?
                                <p className="text-[12px]">Stock disponible: {product.stock}</p> : null
                        }
                    </div>
                </div>
            </Link>
        )
    }else{
        return (
            <Link
                key={product.id}
                className="relative flex flex-col border-1 border-white sm:border-black flex-shrink-0 sm:rounded-[5px] h-[300px] w-[50%] sm:w-[32%] lg:w-[24%] xl:w-[220px] text-center hover:shadow-2xl hover:border-gray-03 duration-200"
                to={`/producto/${encodeURIComponent(productNameUrl)}/${product.id}`}
            >
                {
                    product.image === "sin imagen" ?
                        <div
                            className="h-[150px] text-[20px] flex flex-grow w-[100%] bg-blue lex justify-center items-center text-white text-[18px]"
                        >
                            <p className="p-3">{product.name.toUpperCase()}</p>
                            {
                                product.precioPromo !== "0,00" &&
                                <span style={{width:139, borderTopRightRadius:3}}
                                      className="bg-error absolute top-0 text-white right-0">
          <span>OFFER</span>
          </span>
                            }
                        </div> :
                        <div className="h-[150px] text-[20px] flex flex-grow w-[100%]"
                        >
                            <img
                                className="h-[190px] sm:h-[190px] object-cover rounded-[5px] w-[100%]"
                                src={product.image}
                            />
                            {
                                product.precioPromo !== "0,00" &&
                                <span style={{width:139, borderTopRightRadius:3}}
                                      className="bg-error absolute right-0 top-0 text-white">
          <span>OFERTA</span>
          </span>
                            }
                        </div>
                }
                <div style={{backgroundColor: colores.ecommerce_colorTerciario}}  className="flex flex-col items-start text-left text-blue p-3 h-[130px] justify-between">
                    <p className="text-[13px] text-left min-h-[45px]">
                        {product.name?.length > 30
                            ? product.name.slice(0, 30).toUpperCase() + '...'
                            : product.name?.toUpperCase()}
                    </p>
                    <div>
                        <p className={`font-bold text-[17px] US$ {product.precioPromo !== "0,00" && "line-through text-[15px]"}`}>US$ {product.price}</p>
                        {product.precioPromo !== "0,00" && <p className="font-bold text-[17px]"> US$ {product.precioPromo}</p>}
                        {
                            product.es_digital === "0" && product.stock !== 0 ?
                                <p className="text-[12px]">Quote available: {product.stock}</p> : null
                        }
                    </div>
                </div>
            </Link>
        )
    }
}
