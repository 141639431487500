import SliderBanners from './SliderBanners'
import MostSoldProducts from './MostSoldProducts'
import CarouselBrandCategory from './CarouselBrandCategory'
import { CatalogProducts } from './CatalogProducts'
import InfoEcommerce from './InfoEcommerce'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { UseScrollToTop } from './../utilities/UseScrollToTop'
import { ModalMaintenance } from './ModalMaintenance'
import CarouselProductsHome from './CarouselProductsHome'
import { pais } from './../config'
export default function Home() {

  let dataStore = useSelector((state) => state.dataStore)
  let { actualPage } = useParams()
  let { productName } = useParams()

  let titles = dataStore?.default_categorias === "1" && dataStore?.default_marcas === "1"
  ? ['Categorías','Marcas'] :
  dataStore?.default_categorias === "1" && dataStore?.default_marcas === "0"
  ? ['Categorías'] : ['Marcas']
  let enMantenimiento = dataStore?.default_mantenimiento

  localStorage.setItem('productoIdReturn','0')
  localStorage.setItem('productoNameReturn','null')

  if(pais==='AR'){
      return (
          <>
              <ModalMaintenance
                  show={enMantenimiento}
              />
              {!actualPage && <UseScrollToTop />}
              <SliderBanners />
              <InfoEcommerce />
              {productName === undefined ? <CarouselBrandCategory titles={titles} /> : null}
              {dataStore?.default_masvendido === '1' && productName === undefined ? (
                  <MostSoldProducts titles={['más vendidos']} />
              ) : null}
              <CatalogProducts productNameOk={productName} />
          </>
      )
  }else{
      return (
          <>
              <ModalMaintenance
                  show={enMantenimiento}
              />
              {!actualPage && <UseScrollToTop />}
              <SliderBanners />
              <InfoEcommerce />
              {productName === undefined ? <CarouselBrandCategory titles={titles} /> : null}
              {dataStore?.default_masvendido === '1' && productName === undefined ? (
                  <MostSoldProducts titles={['Best Sellers']} />
              ) : null}
              <CatalogProducts productNameOk={productName} />
          </>
      )
  }
}
