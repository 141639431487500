import { MagnifyingGlass } from '@phosphor-icons/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getProducts } from '../methods'
import { setCatalogProducts } from './../actions'
import { useDispatch, useSelector } from 'react-redux'
import { Toaster, toast } from 'sonner'
import { pais } from './../config'
export default function SearchBar() {
  const navigate = useNavigate()

  const dispatch = useDispatch()
  let configData = useSelector((state) => state.configData)

  const [productName, setProductName] = useState('')

  const [arraySearch, setArraySearch] = useState([])

  async function handleSearch(productName) {
    setProductName('')
    setArraySearch([])
    const data = await getProducts(1, 12, productName, configData.api_key)
    if (data.lastPage > 0) {
      dispatch(setCatalogProducts(data))
      navigate(`./${productName}/1`)
    } else {
      if(pais === 'AR'){
        toast(
        <div className="flex flex-col gap-2 text-[13px]">
          <p>No hay ningún producto que cumpla con esta búsqueda</p>
        </div>
      )}else{
        toast(
            <div className="flex flex-col gap-2 text-[13px]">
              <p>not result, please re search</p>
            </div>
        )
      }

    }
  }

  async function handleSearchOnChange(productName) {
    if(productName !== ''){
      localStorage.setItem('productName',productName)
    }
    setProductName(productName)
    if (!productName) {
      setArraySearch([])
    } else {
      if(productName.length > 3){
        const { data } = await getProducts(1, 4, productName, configData.api_key)
        setArraySearch(data.map((product) => product.name))
      }
    }
  }

  async function handleSelectSearch(productName) {
    setProductName('')
    setArraySearch([])
    const data = await getProducts(1, 12, productName, configData.api_key)
    dispatch(setCatalogProducts(data))
    navigate(`./${productName}/1`)
  }

  if(pais === 'AR'){

    return (
    <div className="w-[100%] m-2 mt-4 relative flex items-center bg-white rounded-[5px] rounded-r-[20px]">
      <Toaster />
      <input
        className="p-2 w-[100%] rounded-[5px] sm:w-[280px] lg:w-[380px] focus:outline-none"
        placeholder="Buscá tu producto"
        value={productName}
        onChange={(e) => handleSearchOnChange(e.target.value)}
      />
      <button
        className="bg-black border-1 border-white text-white rounded-[5px] p-2 hover:text-blue duration-200"
        onClick={() => handleSearch(productName)}
      >
        <MagnifyingGlass size={20} />
      </button>
      {arraySearch.length > 0 && (
        <ul className="flex flex-col absolute w-full top-[40px] z-10 bg-white rounded-b-[5px]">
          {arraySearch.map((productName) => (
            <li className="">
              <button
                className="text-left w-full p-2 hover:cursor-pointer hover:bg-gray-01"
                onClick={() => handleSelectSearch(productName)}
              >
                {productName.slice(0, 35)}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  )}else{
    return (
        <div className="w-[100%] m-2 mt-4 relative flex items-center bg-white rounded-[5px] rounded-r-[20px]">
          <Toaster />
          <input
              className="p-2 w-[100%] rounded-[5px] sm:w-[280px] lg:w-[380px] focus:outline-none"
              placeholder="Search your produtcs"
              value={productName}
              onChange={(e) => handleSearchOnChange(e.target.value)}
          />
          <button
              className="bg-black border-1 border-white text-white rounded-[5px] p-2 hover:text-blue duration-200"
              onClick={() => handleSearch(productName)}
          >
            <MagnifyingGlass size={20} />
          </button>
          {arraySearch.length > 0 && (
              <ul className="flex flex-col absolute w-full top-[40px] z-10 bg-white rounded-b-[5px]">
                {arraySearch.map((productName) => (
                    <li className="">
                      <button
                          className="text-left w-full p-2 hover:cursor-pointer hover:bg-gray-01"
                          onClick={() => handleSelectSearch(productName)}
                      >
                        {productName.slice(0, 35)}
                      </button>
                    </li>
                ))}
              </ul>
          )}
        </div>
    )
  }
}
