import { useEffect, useState, useRef } from 'react'

import { getBrandsWithoutPagination, getCategoriesWithoutPagination, getProductosHome } from '../methods/index'

import { CaretRight, CaretLeft } from '@phosphor-icons/react'

import BrandCategoryCard from './BrandCategoryCard'

import { useSelector } from 'react-redux'
import ProductHomeCard from './ProductHomeCard'
import { pais } from './../config'
export default function CarouselProductsHome({mostrarTitulo}) {
  const sliderRef = useRef()

  let configData = useSelector((state) => state.configData)
  let dataStore = useSelector((state) => state.dataStore)

  const [products, setProducts] = useState([])
  const [page, setPage] = useState(1)
  const [paginaUltima, setPaginaUltima] = useState(1)
  const [ok, setOK] = useState(false)

  useEffect(() => {
    if (configData) {
      if(dataStore){
        if(ok === false){
          ;(async () => {
            setPage(1)
            let data = await getProductosHome(1)
            setProducts(data)
            setOK(true)
          })()
        }
      }
    }
  }, [configData, dataStore])

  async function handleBackSlide(e) {
    sliderRef.current.scrollLeft -= sliderRef.current.clientWidth + 20
  }

  async function handleNextSlide(e) {
    if(page < paginaUltima){
      setPage(page+1)
      let data = await getProductosHome(page+1)
      setProducts(data)
    }
    sliderRef.current.scrollLeft += sliderRef.current.clientWidth + 20
  }
  if(pais === 'AR') {
    return (
        <div className={`pb-12 ${products.length === 0 && "hidden"}`}>
          {
              mostrarTitulo &&
              <div className="flex justify-end w-max my-[25px] gap-2 bg-black py-2 px-8 pl-20 rounded-r-[15px] text-blue text-[16px]">
                <button
                >
                  PRODUCTOS
                </button>
              </div>
          }
          <div className="relative sm:w-[80vw] mx-auto ">
            <CaretLeft
                className="bg-black p-[0.3rem] pl-0 w-[3em] sm:w-[2em] h-[2em] sm:p-1 rounded-r-[20px] sm:rounded-full text-blue absolute top-1/2 left-0 sm:left-[-15px] z-10 hover:cursor-pointer hover:text-black hover:bg-blue duration-200"
                onClick={handleBackSlide}
                size={40}
            />
            <div
                className="flex sm:gap-[5px] lg:gap-[10px] justify-start overflow-x-hidden py-1"
                ref={sliderRef}
            >
              {products?.map((d) => (
                  <ProductHomeCard data={d}/>
              ))}
            </div>
            <CaretRight
                className="bg-black p-[0.3rem] pr-0 w-[3em] sm:w-[2em] h-[2em] sm:p-1 rounded-l-[20px] sm:rounded-full text-blue absolute top-1/2 right-0 sm:right-[-15px] z-10 hover:cursor-pointer hover:text-black hover:bg-blue duration-200"
                onClick={handleNextSlide}
                size={40}
            />
          </div>
        </div>
    )
  }else{
    return (
        <div className={`pb-12 ${products.length === 0 && "hidden"}`}>
          {
              mostrarTitulo &&
              <div className="flex justify-end w-max my-[25px] gap-2 bg-black py-2 px-8 pl-20 rounded-r-[15px] text-blue text-[16px]">
                <button
                >
                  PRODUCTS
                </button>
              </div>
          }
          <div className="relative sm:w-[80vw] mx-auto ">
            <CaretLeft
                className="bg-black p-[0.3rem] pl-0 w-[3em] sm:w-[2em] h-[2em] sm:p-1 rounded-r-[20px] sm:rounded-full text-blue absolute top-1/2 left-0 sm:left-[-15px] z-10 hover:cursor-pointer hover:text-black hover:bg-blue duration-200"
                onClick={handleBackSlide}
                size={40}
            />
            <div
                className="flex sm:gap-[5px] lg:gap-[10px] justify-start overflow-x-hidden py-1"
                ref={sliderRef}
            >
              {products?.map((d) => (
                  <ProductHomeCard data={d}/>
              ))}
            </div>
            <CaretRight
                className="bg-black p-[0.3rem] pr-0 w-[3em] sm:w-[2em] h-[2em] sm:p-1 rounded-l-[20px] sm:rounded-full text-blue absolute top-1/2 right-0 sm:right-[-15px] z-10 hover:cursor-pointer hover:text-black hover:bg-blue duration-200"
                onClick={handleNextSlide}
                size={40}
            />
          </div>
        </div>
    )
  }
}
