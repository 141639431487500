import { Link } from 'react-router-dom'
import { Chat, WhatsappLogo } from '@phosphor-icons/react'
import useComponentVisible from '../utilities/UseComponentVisible.jsx'
import { pais } from './../config'

export const Help = ({ dataStore }) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)
    if(pais === 'AR') {

        return (
            <div className="relative w-[100%]" ref={ref}>
                {/* NAVBAR */}
                <div
                    className='font-bold w-max flex gap-2 justify-items-end items-center py-1 px-2 rounded-[5px] hover:cursor-pointer hover:text-blue duration-200'
                    onClick={() => setIsComponentVisible(!isComponentVisible)}
                >
                    <p className="text-center">
                        <Chat size={36}/>
                        Ayuda
                    </p>
                </div>
                {/*AYUDA */}
                {isComponentVisible && (
                    <div
                        className="flex flex-col items-center gap-2 text-white z-10 absolute top-[70px] right-[0px] bg-white p-2 rounded shadow-xl border-1 border-gray-01 min-w-[180px] max-w-[500px]">
                        <button
                            className="flex items-center justify-center border-1 border-black bg-black p-2 text-sm text-white rounded-[5px] w-full cursor-default duration-200"
                        >
                            <Link
                                className='flex'
                                to={dataStore?.url_whatsapp_api}
                                target="_blank"
                            >
                                <WhatsappLogo size={20} style={{marginRight: 2}}/>
                                {dataStore?.url_whatsapp_api.split('/')[3]}
                            </Link>
                        </button>
                        <button
                            className="flex items-center justify-center border-1 border-black bg-black p-2 text-sm text-white rounded-[5px] w-full cursor-default duration-200"
                        >
                            {dataStore?.mail_direccion}
                        </button>
                    </div>
                )}
            </div>
        )
    }else{

        return (
            <div className="relative w-[100%]" ref={ref}>
                {/* NAVBAR */}
                <div
                    className='font-bold w-max flex gap-2 justify-items-end items-center py-1 px-2 rounded-[5px] hover:cursor-pointer hover:text-blue duration-200'
                    onClick={() => setIsComponentVisible(!isComponentVisible)}
                >
                    <p className="text-center">
                        <Chat size={36} />
                        Help
                    </p>
                </div>
                {/*AYUDA */}
                {isComponentVisible && (
                    <div className="flex flex-col items-center gap-2 text-white z-10 absolute top-[70px] right-[0px] bg-white p-2 rounded shadow-xl border-1 border-gray-01 min-w-[180px] max-w-[500px]">
                        <button
                            className="flex items-center justify-center border-1 border-black bg-black p-2 text-sm text-white rounded-[5px] w-full cursor-default duration-200"
                        >
                            <Link
                                className='flex'
                                to={dataStore?.url_whatsapp_api}
                                target="_blank"
                            >
                                <WhatsappLogo size={20} style={{marginRight:2}}/>
                                {dataStore?.url_whatsapp_api.split('/')[3]}
                            </Link>
                        </button>
                        <button
                            className="flex items-center justify-center border-1 border-black bg-black p-2 text-sm text-white rounded-[5px] w-full cursor-default duration-200"
                        >
                            {dataStore?.mail_direccion}
                        </button>
                    </div>
                )}
            </div>
        )
    }
}
