import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'html-react-parser';
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom'

import {
  getConfigStore,
  getMensajesProducto,
  getProductDetail,
  getProductsByCategoryOrBrand,
  getTallas,
  sendMessageProducto,
} from '../methods/index'
import { setInvoiceId, setTotalProducts } from './../actions/index'
import {
  addProductToCart as addProductMethod,
  getTotalProducts,
} from './../methods/index'

import { LineWobble } from '@uiball/loaders'
import { Toaster, toast } from 'sonner'

import ModalImage from 'react-modal-image'

import { urlCore, pais } from '../config'

import { UseScrollToTop } from './../utilities/UseScrollToTop'
import { matchTallaProducto } from '../methods/utilsMethods';

import { ModalMaintenance } from './ModalMaintenance'
import MostSoldProducts from './MostSoldProducts';
import CarouselProductsHome from './CarouselProductsHome';
import mercadopago from './mercado-pago.png'
import pickit from './logo-pickit.png'
import envioDomicilio from './envio-domicilio.png'
import retiro from './retiro-local.png'
import acordar from './acordar-vendedor.png'

import {
  setDataStore,
  setDataStore2,
  setConfig,
  setClientSession,
} from '../actions/index'
import { WhatsappLogo } from '@phosphor-icons/react';
import { ModalMessageProduct } from './ModalMessageProduct';

export default function ProductDetail() {
  let location = useLocation()
  let locationURL = window.location.origin+location.pathname
  let dataStore = useSelector((state) => state.dataStore)
  let configData = useSelector((state) => state.configData)
  let enMantenimiento = dataStore?.default_mantenimiento
  let default_marcas = dataStore?.default_marcas

  let { id } = useParams()
  let { name } = useParams()
  let dispatch = useDispatch()
  let clientSession = useSelector((state) => state.clientSession)

  let categoryOrBrand = localStorage.getItem('categoryOrBrand')

  let user = useSelector((state) => state.user)

  const [lengthDesc, setLengthDesc] = useState(400)
  const [pathSelected, setPathSelected] = useState('')
  const [nameChanged, setNameChanged] = useState(false)

  const [product, setProduct] = useState({})
  const [category, setCategory] = useState('')
  const [quantity, setQuantity] = useState(1)
  const [info, setInfo] = useState({colores: [], tallas: {}})
  const [talla, setTalla] = useState('')
  const [color, setColor] = useState('')
  const [productId, setProductId] = useState('')
  const [cantidad, setCantidad] = useState('')
  const [messages, setMessages] = useState([])

  let navigate = useNavigate()

  const [show, setShow] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if(user){
      setShow(true)
    }else{
      localStorage.setItem('productoIdReturn',id)
      localStorage.setItem('productoNameReturn',name)
      navigate('/registrarse')
    }
  }

  useEffect(() => {
    setNameChanged(true)
  },[name])


  useEffect(() => {
      ;(async () => {
        let splitWith = window.location.href.includes('.com.ar')
        ? '.com.ar'
        : '.com'
      let url = window.location.href.split(splitWith)[0] + splitWith
      const config = await getConfigStore(url)
      dispatch(setConfig(config))
      let productDetail = await getProductDetail(id)
      setProduct(productDetail)
      if(Object.keys(productDetail).length > 1){
        let { data, lastPage } = await getProductsByCategoryOrBrand(
          productDetail.category,
          0,
          1
        )
        let infoAtributos = matchTallaProducto(productDetail.asociados.filter((p) => p.activo === "1"))
        setColor(productDetail.color)
        setInfo(infoAtributos)
        if(data.length > 0){
          setCategory(data[0].category)
        }
        setPathSelected(productDetail.images[0])
        const messagesList = await getMensajesProducto(id,user)
        setMessages(messagesList)
      }
    })()
  }, [id])

  useEffect(() => {
    ;(async () => {
      const messagesList = await getMensajesProducto(id,user)
      setMessages(messagesList)
    })()
  }, [id,user])

  // console.log(product)
  async function handleAddToCart() {
    let cantidadProd = quantity

      let clientId = user ? user.id : clientSession
      let invoiceIdgetted
      if(productId !== ''){
        invoiceIdgetted = await addProductMethod(
          productId,
          cantidadProd,
          clientId
        )
      }else{
        invoiceIdgetted = await addProductMethod(
          product.sku,
          quantity,
          clientId
        )
      }

      if (invoiceIdgetted) {
        dispatch(setInvoiceId(invoiceIdgetted))
        dispatch(setTotalProducts(await getTotalProducts(clientId)))
        if(pais === 'AR') {
          toast(
              <div className="flex flex-col gap-2 text-[16px]">
                <p>Producto agregado al carrito:</p>
                <Link
                    className="font-bold text-blue rounded-[5px] p-1 hover:bg-black duration-200"
                    to="/carrito"
                >
                  {quantity} {product.name}
                </Link>
              </div>
          )
        }else{
          toast(
              <div className="flex flex-col gap-2 text-[16px]">
                <p>Product add to cart:</p>
                <Link
                    className="font-bold text-blue rounded-[5px] p-1 hover:bg-black duration-200"
                    to="/carrito"
                >
                  {quantity} {product.name}
                </Link>
              </div>
          )
        }
      } else {
        if(pais === 'AR') {
          toast.error('Hubo un error en agregar este producto al carrito')
        }else{
          toast.error('Error when you add producto to cart')
        }
      }
      setQuantity(1)
  }

  // CONTROL STOCK AND QUANTITY SELECTED
  function handleTotalQuantity(qty) {
    if (qty < 1) return
    if (qty > product.stock) {

      toast.error('No contamos con esa cantidad en el stock')

      return
    }
    setQuantity(+qty)
  }

  let nameURL = name.replace(/\s+/g, '-')

  const [input, setInput] = useState({
    titulo: '',
    mensaje: '',
    usuariodestinoid: '',
    productoid: ''
  })

  const handleChange = (e) => {
    e.preventDefault()
    setInput({
        ...input,
        [e.target.name]: e.target.value
    })
  }

  async function handleSendMessage(input) {
    let response = await sendMessageProducto(input,user)
    setInput({
        titulo: '',
        mensaje: '',
        usuariodestinoid: '',
        productoid: ''
    })
    const messagesList = await getMensajesProducto(id,user)
    setMessages(messagesList)
  }
  if(pais === 'AR') {
    return (
        <div>
          <ModalMaintenance
              show={enMantenimiento}
          />
          <ModalMessageProduct
              show={show}
              handleClose={handleClose}
              messages={messages}
              pedidoid={id}
              clienteid={''}
              input={input}
              setInput={setInput}
              sendMessage={handleSendMessage}
              handleChange={handleChange}
          />
          {nameChanged && <UseScrollToTop/>}
          {color === '' && <UseScrollToTop />}
          <Toaster />
          {Object.keys(product).length > 1 ? (
              <div className="w-[80vw] mx-auto mb-20">
                {
                  categoryOrBrand === "categorias" ?
                      <p className="text-[13px] my-12">
                        CATEGORÍAS /
                        <Link
                            className="ml-2 font-bold text-[16px] px-2 py-1 rounded-[5px] hover:text-blue hover:bg-black duration-200"
                            to={`/productos/categorias/${category}/${product.category}/1`}
                        >
                          {category ? category.toUpperCase() : 'SIN CATEGORÍA'}
                        </Link>
                      </p> :
                      <p className="text-[13px] my-12">
                        MARCAS /
                        <Link
                            className="ml-2 font-bold text-[16px] px-2 py-1 rounded-[5px] hover:text-blue hover:bg-black duration-200"
                            to={`/productos/marcas/${product.brand}/${product.brandid}/1`}
                        >
                          {product.brand ? product.brand.toUpperCase() : 'SIN MARCA'}
                        </Link>
                      </p>
                }
                {/* SI LLEGA A TENER IMÁGENES EL PRODUCTO: */}
                <div className="flex flex-col md:flex-row gap-4 my-4">
                  <div className="flex flex-row md:flex-col flex-shrink-0 md:w-[100px] gap-2">
                    {product.images?.map((path, i) => (
                        <div key={i}>
                          <img
                              key={i}
                              onClick={() => setPathSelected(path)}
                              className={`w-[50px] md:w-full border-1 border-black h-[50px] md:h-[100px] object-cover rounded-[5px] brightness-[.8] hover:cursor-pointer hover:border-white 
                    ${path === pathSelected && 'brightness-100 border-white'}`}
                              src={urlCore + path}
                              alt={product.name}
                          />
                        </div>
                    ))}
                  </div>
                  <div className="w-[300px] flex-shrink-0">
                    <ModalImage
                        small={urlCore + pathSelected}
                        large={urlCore + pathSelected}
                        className="border-1 border-black h-[280px] w-full object-cover rounded-[5px]"
                        hideDownload={true}
                        hideZoom={true}
                        alt={`Imagen de ${product.name}`}
                    />
                  </div>


                  <div className='w-full'>
                    {
                      product.precioPromo !== "0,00" ?
                          <span className="bg-error flex justify-center w-32 text-white">
                  <span>OFERTA</span>
                </span> : null
                    }
                    <h2 style={{marginTop:10}} className="font-bold text-[20px]">{product.name}</h2>
                    <p className={`font-bold text-[17px] text-blue ${product.precioPromo === "0,00" && "border-b-1 border-gray-03 pb-2"} ${product.precioPromo !== "0,00" && "line-through text-[15px]"}`}>${product.price}</p>
                    {product.precioPromo !== "0,00" &&
                        <p className="font-bold text-[17px] text-blue border-b-1 border-gray-03 pb-2">${product.precioPromo}</p>}
                    <div>

                      <div className='flex w-full max-lg:flex-wrap'>

                        <div className='w-6/12 mr-5 max-lg:w-full'>

                          <h3 className="font-bold mt-6 mb-2">INFORMACIÓN</h3>

                          <ul className="flex flex-col gap-2 text-[13px]">
                            <li>
                              <span className="font-bold">SKU:</span> {productId === '' ? product.sku : productId}
                            </li>
                            {
                              product.es_digital === "0" && product.stock !== 0 ?
                                  <li>
                                    <span className="font-bold">STOCK:</span> {cantidad === '' ? product.stock : cantidad}
                                  </li> : null
                            }
                            <li>
                              {product.es_digital === "1" && product.brand ? null :
                                  product.es_digital === "0" && product.brand === '' ? <span className="font-bold">MARCA: Ninguna</span> :
                                      <span className="font-bold">MARCA: {product.brand}</span>
                              }
                            </li>
                            <li className='flex'>
                              {
                                  info.colores.length > 0 && info.colores.map((c) =>
                                      <Link
                                          key={c.color}
                                          className={`flex justify-center border-1 border-gray-02 text-[14px] mr-1 w-1/2 h-8 ${
                                              c.color === color ? 'bg-black text-blue' : 'bg-grey text-black'}`}
                                          to={`/producto/${product.name}/${c.productoid}`}>
                                        <button
                                            onClick={() => setColor(c.color)}
                                        >
                                          {c.color}
                                        </button>
                                      </Link>
                                  )
                              }
                            </li>
                            <li className='flex flex-wrap'>
                              {
                                color !== '' && Object.keys(info.tallas).length > 0 ? info.tallas[color].map((t) =>
                                    <button
                                        key={t.sku}
                                        className={`border-1 border-gray-02 text-[14px] w-1/4 h-10 overflow-hidden ${t.medida === talla ? 'bg-black text-blue' : 'bg-grey text-black'}`}
                                        onClick={() =>{
                                          setTalla(t.medida)
                                          setProductId(t.sku)
                                          setCantidad(t.cantidad)
                                        }}
                                    >
                                      {t.medida}
                                    </button>
                                ) : null
                              }
                            </li>
                          </ul>
                          {product.description.length > lengthDesc ? (
                              <>
                                {parse(product.description).slice(0, lengthDesc) + '...'}
                                <button
                                    className="ml-2 bg-black text-blue p-1 px-2 text-xs rounded-[5px] hover:text-white duration-200"
                                    onClick={() => setLengthDesc(Infinity)}
                                >
                                  Ver más
                                </button>
                              </>
                          ) : (
                              parse(product.description)
                          )}

                        </div>

                        <div className='flex flex-col w-6/12 mt-5 ml-2 max-lg:w-full'>
                          <div className="flex flex-col w-full mb-2">
                            <h3 className="text-black font-bold text-[16px]">
                              Medios de env&iacute;o
                            </h3>
                            <ul className="mt-2 flex items-center">
                              {
                                  dataStore?.default_pickit === "1" &&
                                  <li
                                      className="w-32 h-20 p-0 b-0"
                                  >
                                    <img src={pickit} className='w-full h-full' alt='Pickit'/>
                                  </li>
                              }
                              {
                                  dataStore?.default_enviocliente === "1" &&
                                  <li
                                      className="w-32 h-20 p-0 b-0"
                                  >
                                    <img src={envioDomicilio} className='w-full h-full' alt='Envio a domicilio'/>
                                  </li>
                              }
                              {
                                  dataStore?.default_retirolocal === "1" &&
                                  <li
                                      className="w-32 h-20 p-0 b-0"
                                  >
                                    <img src={retiro} className='w-full h-full' alt='Retiro en el local'/>
                                  </li>
                              }
                            </ul>
                          </div>
                          <div className="flex flex-col w-full mb-2">
                            <h3 className="text-black font-bold text-[16px]">
                              Medios de pago
                            </h3>
                            <ul className="mt-2 flex items-center">
                              {
                                  dataStore?.default_mercadopago === "1" &&
                                  <li
                                      className="w-32 h-20 p-0 b-0"
                                  >
                                    <img src={mercadopago} className='w-full h-full' alt='MercadoPago'/>
                                  </li>
                              }
                              {
                                  dataStore?.default_efectivo === "1" &&
                                  <li
                                      className="w-32 h-20 p-0 b-0"
                                  >
                                    <img src={acordar} className='w-full h-full' alt='Acordar con el vendedor'/>
                                  </li>
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                      <h3 className="font-bold mb-2">CANTIDAD</h3>
                      <div className="flex gap-2">
                        <input
                            className="text-[16px] border-1 border-gray-02 rounded-[5px] p-1 text-center focus:outline-0 focus:border-blue"
                            type="number"
                            value={quantity}
                            onChange={(e) => handleTotalQuantity(+e.target.value)}
                        />
                        <button
                            className="p-2 w-[40px] bg-black text-blue rounded-[5px] font-bold hover:text-white"
                            onClick={() => handleTotalQuantity(quantity - 1)}
                        >
                          -
                        </button>
                        <button
                            className="p-2 w-[40px] bg-black text-blue rounded-[5px] font-bold hover:text-white"
                            onClick={() => handleTotalQuantity(quantity + 1)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <ul className="flex gap-2 my-2">
                      <li>
                        <button
                            className="w-[50px] text-xs p-2 border-gray-02 text-gray-02 border-1 rounded-[10px] hover:border-black hover:text-black duration-200"
                            onClick={() => handleTotalQuantity(10)}
                        >
                          10
                        </button>
                      </li>
                      <li>
                        <button
                            className="w-[50px] text-xs p-2 border-gray-02 text-gray-02 border-1 rounded-[10px] hover:border-black hover:text-black duration-200"
                            onClick={() => handleTotalQuantity(50)}
                        >
                          50
                        </button>
                      </li>
                      <li>
                        <button
                            className="w-[50px] text-xs p-2 border-gray-02 text-gray-02 border-1 rounded-[10px] hover:border-black hover:text-black duration-200"
                            onClick={() => handleTotalQuantity(100)}
                        >
                          100
                        </button>
                      </li>
                    </ul>
                    <div>
                      <button
                          className="w-4/12 max-lg:w-full my-2 border-1 border-black bg-black text-blue px-6 py-2 rounded-[5px] text-[13px] hover:bg-white hover:text-black duration-200"
                          onClick={() => handleAddToCart()}
                      >
                        AGREGAR AL CARRITO
                      </button>

                      <div className='flex'>

                        <a
                            href={`https://web.whatsapp.com/send?text= ${product.name} ${'https://'+configData.dominio+'/producto/'+nameURL+'/'+id}`}
                            rel="nofollow noopener" target="_blank"
                            className="w-24 flex items-center justify-center my-1 bg-success text-blue px-2 py-2 rounded-[5px] text-[13px] duration-200"
                        >
                          <button
                              className='flex items-center justify-center'
                          >
                            <WhatsappLogo size={25} color='white' style={{marginRight:5, fontWeight:'lighter'}}/>
                            <p className='text-white text-[15px]'>Share</p>
                          </button>
                        </a>
                        <button
                            onClick={()=>handleShow()}
                            className='text-sm bg-blue hover:bg-black hover:text-blue px-2 mx-2 my-1 text-white rounded-[5px]'
                        >
                          Preguntas al vendedor
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
          ) : (
              <div className="h-[80vh] flex justify-center items-center">
                <h3 className='text-[25px] text-error' >{product.message}</h3>
              </div>
          )}
          {dataStore?.default_masvendido === '1' ?
              <MostSoldProducts titles={['más vendidos']} /> :
              dataStore?.default_productohome === '1' ?
                  <CarouselProductsHome mostrarTitulo={true}/> : null
          }
        </div>
    )
  }else{
    return (
        <div>
          <ModalMaintenance
              show={enMantenimiento}
          />
          <ModalMessageProduct
              show={show}
              handleClose={handleClose}
              messages={messages}
              pedidoid={id}
              clienteid={''}
              input={input}
              setInput={setInput}
              sendMessage={handleSendMessage}
              handleChange={handleChange}
          />
          {nameChanged && <UseScrollToTop/>}
          {color === '' && <UseScrollToTop />}
          <Toaster />
          {Object.keys(product).length > 1 ? (
              <div className="w-[80vw] mx-auto mb-20">
                {
                  categoryOrBrand === "categorias" ?
                      <p className="text-[13px] my-12">
                        CATEGORIES /
                        <Link
                            className="ml-2 font-bold text-[16px] px-2 py-1 rounded-[5px] hover:text-blue hover:bg-black duration-200"
                            to={`/productos/categorias/${category}/${product.category}/1`}
                        >
                          {category ? category.toUpperCase() : 'SIN CATEGORÍA'}
                        </Link>
                      </p> :
                      <p className="text-[13px] my-12">
                        BRANDS /
                        <Link
                            className="ml-2 font-bold text-[16px] px-2 py-1 rounded-[5px] hover:text-blue hover:bg-black duration-200"
                            to={`/productos/marcas/${product.brand}/${product.brandid}/1`}
                        >
                          {product.brand ? product.brand.toUpperCase() : 'SIN MARCA'}
                        </Link>
                      </p>
                }
                {/* SI LLEGA A TENER IMÁGENES EL PRODUCTO: */}
                <div className="flex flex-col md:flex-row gap-4 my-4">
                  <div className="flex flex-row md:flex-col flex-shrink-0 md:w-[100px] gap-2">
                    {product.images?.map((path, i) => (
                        <div key={i}>
                          <img
                              key={i}
                              onClick={() => setPathSelected(path)}
                              className={`w-[50px] md:w-full border-1 border-black h-[50px] md:h-[100px] object-cover rounded-[5px] brightness-[.8] hover:cursor-pointer hover:border-white 
                    ${path === pathSelected && 'brightness-100 border-white'}`}
                              src={urlCore + path}
                              alt={product.name}
                          />
                        </div>
                    ))}
                  </div>
                  <div className="w-[300px] flex-shrink-0">
                    <ModalImage
                        small={urlCore + pathSelected}
                        large={urlCore + pathSelected}
                        className="border-1 border-black h-[280px] w-full object-cover rounded-[5px]"
                        hideDownload={true}
                        hideZoom={true}
                        alt={`Image of ${product.name}`}
                    />
                  </div>


                  <div className='w-full'>
                    {
                      product.precioPromo !== "0,00" ?
                          <span className="bg-error flex justify-center w-32 text-white">
                  <span>OFFER</span>
                </span> : null
                    }
                    <h2 style={{marginTop:10}} className="font-bold text-[20px]">{product.name}</h2>
                    <p className={`font-bold text-[17px] text-blue ${product.precioPromo === "0,00" && "border-b-1 border-gray-03 pb-2"} ${product.precioPromo !== "0,00" && "line-through text-[15px]"}`}>US$ {product.price}</p>
                    {product.precioPromo !== "0,00" &&
                        <p className="font-bold text-[17px] text-blue border-b-1 border-gray-03 pb-2">${product.precioPromo}</p>}
                    <div>

                      <div className='flex w-full max-lg:flex-wrap'>

                        <div className='w-6/12 mr-5 max-lg:w-full'>

                          <h3 className="font-bold mt-6 mb-2">DESCRIPTION</h3>

                          <ul className="flex flex-col gap-2 text-[13px]">
                            <li>
                              <span className="font-bold">SKU:</span> {productId === '' ? product.sku : productId}
                            </li>
                            {
                              product.es_digital === "0" && product.stock !== 0 ?
                                  <li>
                                    <span className="font-bold">QUOTE:</span> {cantidad === '' ? product.stock : cantidad}
                                  </li> : null
                            }
                            <li>
                              {product.es_digital === "1" && product.brand ? null :
                                  product.es_digital === "0" && product.brand === '' ? <span className="font-bold">MARCA: Ninguna</span> :
                                      <span className="font-bold">BRAND: {product.brand}</span>
                              }
                            </li>
                            <li className='flex'>
                              {
                                  info.colores.length > 0 && info.colores.map((c) =>
                                      <Link
                                          key={c.color}
                                          className={`flex justify-center border-1 border-gray-02 text-[14px] mr-1 w-1/2 h-8 ${
                                              c.color === color ? 'bg-black text-blue' : 'bg-grey text-black'}`}
                                          to={`/producto/${product.name}/${c.productoid}`}>
                                        <button
                                            onClick={() => setColor(c.color)}
                                        >
                                          {c.color}
                                        </button>
                                      </Link>
                                  )
                              }
                            </li>
                            <li className='flex flex-wrap'>
                              {
                                color !== '' && Object.keys(info.tallas).length > 0 ? info.tallas[color].map((t) =>
                                    <button
                                        key={t.sku}
                                        className={`border-1 border-gray-02 text-[14px] w-1/4 h-10 overflow-hidden ${t.medida === talla ? 'bg-black text-blue' : 'bg-grey text-black'}`}
                                        onClick={() =>{
                                          setTalla(t.medida)
                                          setProductId(t.sku)
                                          setCantidad(t.cantidad)
                                        }}
                                    >
                                      {t.medida}
                                    </button>
                                ) : null
                              }
                            </li>
                          </ul>
                          {product.description.length > lengthDesc ? (
                              <>
                                {parse(product.description).slice(0, lengthDesc) + '...'}
                                <button
                                    className="ml-2 bg-black text-blue p-1 px-2 text-xs rounded-[5px] hover:text-white duration-200"
                                    onClick={() => setLengthDesc(Infinity)}
                                >
                                  View more
                                </button>
                              </>
                          ) : (
                              parse(product.description)
                          )}

                        </div>

                        <div className='flex flex-col w-6/12 mt-5 ml-2 max-lg:w-full'>
                        <div className="flex flex-col w-full mb-2">
                          <h3 className="text-black font-bold text-[16px]">
                            Medios de env&iacute;o
                          </h3>
                          <ul className="mt-2 flex items-center">
                            {
                                dataStore?.default_pickit === "1" &&
                                <li
                                    className="w-32 h-20 p-0 b-0"
                                >
                                  <img src={pickit} className='w-full h-full' alt='Pickit'/>
                                </li>
                            }
                            {
                                dataStore?.default_enviocliente === "1" &&
                                <li
                                    className="w-32 h-20 p-0 b-0"
                                >
                                  <img src={envioDomicilio} className='w-full h-full' alt='Envio a domicilio'/>
                                </li>
                            }
                            {
                                dataStore?.default_retirolocal === "1" &&
                                <li
                                    className="w-32 h-20 p-0 b-0"
                                >
                                  <img src={retiro} className='w-full h-full' alt='Retiro en el local'/>
                                </li>
                            }
                          </ul>
                        </div>

                          <div className="flex flex-col w-full mb-2">
                            <h3 className="text-black font-bold text-[16px]">
                              Medios de pago
                            </h3>
                            <ul className="mt-2 flex items-center">
                              {
                                  dataStore?.default_mercadopago === "1" &&
                                  <li
                                      className="w-32 h-20 p-0 b-0"
                                  >
                                    <img src={mercadopago} className='w-full h-full' alt='MercadoPago'/>
                                  </li>
                              }
                              {
                                  dataStore?.default_efectivo === "1" &&
                                  <li
                                      className="w-32 h-20 p-0 b-0"
                                  >
                                    <img src={acordar} className='w-full h-full' alt='Acordar con el vendedor'/>
                                  </li>
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                      <h3 className="font-bold mb-2">Quantity</h3>
                      <div className="flex gap-2">
                        <input
                            className="text-[16px] border-1 border-gray-02 rounded-[5px] p-1 text-center focus:outline-0 focus:border-blue"
                            type="number"
                            value={quantity}
                            onChange={(e) => handleTotalQuantity(+e.target.value)}
                        />
                        <button
                            className="p-2 w-[40px] bg-black text-blue rounded-[5px] font-bold hover:text-white"
                            onClick={() => handleTotalQuantity(quantity - 1)}
                        >
                          -
                        </button>
                        <button
                            className="p-2 w-[40px] bg-black text-blue rounded-[5px] font-bold hover:text-white"
                            onClick={() => handleTotalQuantity(quantity + 1)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <ul className="flex gap-2 my-2">
                      <li>
                        <button
                            className="w-[50px] text-xs p-2 border-gray-02 text-gray-02 border-1 rounded-[10px] hover:border-black hover:text-black duration-200"
                            onClick={() => handleTotalQuantity(10)}
                        >
                          10
                        </button>
                      </li>
                      <li>
                        <button
                            className="w-[50px] text-xs p-2 border-gray-02 text-gray-02 border-1 rounded-[10px] hover:border-black hover:text-black duration-200"
                            onClick={() => handleTotalQuantity(50)}
                        >
                          50
                        </button>
                      </li>
                      <li>
                        <button
                            className="w-[50px] text-xs p-2 border-gray-02 text-gray-02 border-1 rounded-[10px] hover:border-black hover:text-black duration-200"
                            onClick={() => handleTotalQuantity(100)}
                        >
                          100
                        </button>
                      </li>
                    </ul>
                    <div>
                      <button
                          className="w-4/12 max-lg:w-full my-2 border-1 border-black bg-black text-blue px-6 py-2 rounded-[5px] text-[13px] hover:bg-white hover:text-black duration-200"
                          onClick={() => handleAddToCart()}
                      >
                        ADD TO CART
                      </button>

                      <div className='flex'>

                        <a
                            href={`https://web.whatsapp.com/send?text= ${product.name} ${'https://'+configData.dominio+'/producto/'+nameURL+'/'+id}`}
                            rel="nofollow noopener" target="_blank"
                            className="w-24 flex items-center justify-center my-1 bg-success text-blue px-2 py-2 rounded-[5px] text-[13px] duration-200"
                        >
                          <button
                              className='flex items-center justify-center'
                          >
                            <WhatsappLogo size={25} color='white' style={{marginRight:5, fontWeight:'lighter'}}/>
                            <p className='text-white text-[15px]'>Share</p>
                          </button>
                        </a>
                        <button
                            onClick={()=>handleShow()}
                            className='text-sm bg-blue hover:bg-black hover:text-blue px-2 mx-2 my-1 text-white rounded-[5px]'
                        >
                          Questions to Seller
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
          ) : (
              <div className="h-[80vh] flex justify-center items-center">
                <h3 className='text-[25px] text-error' >{product.message}</h3>
              </div>
          )}
          {dataStore?.default_masvendido === '1' ?
              <MostSoldProducts titles={['más vendidos']} /> :
              dataStore?.default_productohome === '1' ?
                  <CarouselProductsHome mostrarTitulo={true}/> : null
          }
        </div>
    )
  }
}
