import { useEffect, useState, useRef } from 'react'

import { getCategoriesCarousel, getCategoriesWithoutPagination } from '../methods/index'

import { CaretRight, CaretLeft } from '@phosphor-icons/react'

import { useSelector } from 'react-redux'

export default function CarouselCategory() {
  const sliderRef = useRef()

  let configData = useSelector((state) => state.configData)
  let dataStore = useSelector((state) => state.dataStore)

  const [brands, setBrands] = useState([])
  const [page, setPage] = useState(1)
  const [paginaUltima, setPaginaUltima] = useState(1)
  const [ok, setOK] = useState(false)

  useEffect(() => {
    if (configData) {
      if(dataStore){
        if(ok === false){
          ;(async () => {
            setPage(1)
            let  data  = await getCategoriesCarousel(1)
            setBrands(data.data)
            setPaginaUltima(data.paginaUltima)
          })()
          setOK(true)
        }
      }
    }
  }, [configData, dataStore])

  async function handleBackSlide(e) {
    if(page > 0){
      setPage(page-1)
      let  data  = await getCategoriesCarousel(page-1)
      setPaginaUltima(data.paginaUltima)
      setBrands(data.data)
    }
    sliderRef.current.scrollLeft -= sliderRef.current.clientWidth + 20
  }

  async function handleNextSlide(e) {
    if(page < paginaUltima){
      setPage(page+1)
      let  data  = await getCategoriesCarousel(page+1)
      setPaginaUltima(data.paginaUltima)
      setBrands(data.data)
    }
    sliderRef.current.scrollLeft += sliderRef.current.clientWidth + 20
  }

  return (
    <div className="overflow-x-hidden mx-5 max-xl:hidden">
      <div className="relative sm:w-[80vw] mx-auto flex items-center justify-center">
        <CaretLeft
          className="absolute left-3 text-black cursor-pointer"
          onClick={handleBackSlide}
          size={20}
        />
        <div
          className="flex sm:gap-[7px] lg:gap-[15px] justify-around overflow-x-hidden py-1 px-3"
          ref={sliderRef}
        >
          {brands?.map((d) => (
            <a 
              href={`/productos/categorias/${d.name
              ?.replace(/\s+/g, '-').replace(/\//g, '-')
              .toLowerCase()}/${d.id}/1`}
              className='rounded-[5px] hover:text-white hover:bg-black duration-200 p-1 cursor-pointer relative flex-shrink-0 text-[11px] font-medium text-center flex items-center'
            >{d.name.toUpperCase()}
            </a>
          ))}
        </div>
        <CaretRight
          className="absolute text-black right-3 cursor-pointer"
          onClick={handleNextSlide}
          size={20}
        />
      </div>
    </div>
  )
}
