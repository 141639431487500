import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import useComponentVisible from "../utilities/UseComponentVisible";
import { getBrandsWithoutPagination, getBrandsWithoutPaginationA, getCategoriesWithoutPagination, getCategoriesWithoutPaginationA, getSubcategorias } from "../methods";
import { useEffect, useState } from "react";
import { CaretDown, CaretRight, List } from "@phosphor-icons/react";
import CarouselCategory from "./CarouselCategory";
import { UseRemoveTildes } from "../utilities/UseRemoveTildes";
import { pais } from './../config'
const ButtonLink = ({ to, label }) => (
  <NavLink
    className={({ isActive }) =>
      isActive
        ? "gap-2 rounded-b-[5px] p-2 bg-black text-white"
        : "p-2 rounded-b-[5px] hover:text-white hover:bg-black duration-200"
    }
    to={to}
  >
    {label}
  </NavLink>
);

export default function SubNavBar() {

  let user = useSelector((state) => state.user);
  let configData = useSelector((state) => state.configData)
  let dataStore = useSelector((state) => state.dataStore)

  let botones = dataStore?.default_categorias === "1" && dataStore?.default_marcas === "1"
  ?   [

    { to: "/categorias/1", label: "CATEGORÍAS" },
    { to: "/marcas/1", label: "MARCAS" },
    { to: "/mis-pedidos", label: "MIS PEDIDOS" },
    { to: "/tiendas", label: "TIENDAS" },
  ] :
  dataStore?.default_categorias === "1" && dataStore?.default_marcas === "0"
  ?   [
    { to: "/categorias/1", label: "CATEGORÍAS" },
    { to: "/mis-pedidos", label: "MIS PEDIDOS" },
    { to: "/tiendas", label: "TIENDAS" },
  ] :
  [
    { to: "/marcas/1", label: "MARCAS" },
    { to: "/mis-pedidos", label: "MIS PEDIDOS" },
    { to: "/tiendas", label: "TIENDAS" },
  ]


  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [brands, setBrands] = useState([])
  const [openCategorias, setOpenCategorias] = useState(false)
  const [openSubs, setOpenSubs] = useState(false)
  const [categoryName, setCategoryName] = useState('')

  const [openCategoriasResp, setOpenCategoriasResp] = useState(false)

  async function handleGetSubCategories(data) {
    setCategoryName(data.categoria)
    setSubCategories(data.subcategorias)
  }

  const [page, setPage] = useState(1)

  let menu_desplegable = dataStore?.menu_desplegable

  const [ok, setOk] = useState(false)

  useEffect(() => {
    if(configData){
      if(ok === false){
        ;(async () => {
          let dataC = await getCategoriesWithoutPaginationA(1)
          let dataB = await getBrandsWithoutPaginationA(1)
          setCategories(dataC.data)
          setBrands(dataB.data)
        })()
        setOk(true)
      }
    }
  }, [configData])

  if(pais==='AR'){
    return (
        <div className={`bg-blue p-1 pt-0 text-[12px] h-10 ${menu_desplegable === "1" && 'h-14'}` }>
          <div className="flex gap-4 w-full mx-auto font-bold items-center">
            {
              menu_desplegable === "1" ?

                  <div className="flex items-center p-3">
                    <button
                        className="flex items-center rounded-[5px] p-1 hover:text-white hover:bg-black duration-200 text-[16px]"
                        onMouseOver={() => {
                          setOpenCategorias(!openCategorias)
                          if(!openCategorias === false){
                            setSubCategories([])
                          }
                        }}
                        onClick={() => {
                          setOpenCategorias(!openCategorias)
                        }}
                    >


                      <List size={20} style={{marginRight:10}}/> CATEGORÍAS

                    </button>
                    <CarouselCategory/>
                  </div> :

                  botones.map((boton, index) => {
                    if (boton.label === "MIS PEDIDOS") {
                      if (!user) return null;
                    }
                    if (boton.label === "TIENDAS") {
                      if (dataStore?.multisucursal === "0") return null;
                    }
                    return <ButtonLink key={index} to={boton.to} label={boton.label} />;
                  })

            }
          </div>

          {openCategorias && (
              <div className="flex absolute w-[100%] max-md:top-[190px] min-md:top-[140px] left-0"
                   onMouseLeave={() => {
                     setOpenCategorias(false)
                     setSubCategories([])
                   }}>
                <div className="max-md:w-[100%] w-[25%] flex flex-col z-10 bg-white p-4 rounded border-1 border-gray-01">
                  {
                      categories.length > 0 && categories.map((c) =>
                          Object.values(c.subcategorias).length > 0 ?

                              <div key={c.id}>
                                <p
                                    onClick={() => {
                                      setOpenSubs(!openSubs)
                                      handleGetSubCategories({categoria: c.name, subcategorias: Object.values(c.subcategorias)})
                                    }}
                                    onMouseOver={() => {
                                      setSubCategories([])
                                      handleGetSubCategories({categoria: c.name, subcategorias: Object.values(c.subcategorias)})
                                    }}
                                    className="flex items-center justify-between rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
                                >{c.name} <CaretRight/>
                                </p>
                                {categoryName === c.name && openSubs === true &&(
                                    <div className="min-md:hidden w-full flex flex-col z-10 bg-white p-2 right-30 rounded border-gray-01">
                                      {
                                        c.subcategorias.map((c) =>

                                            <a
                                                className="rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
                                                href={`/productos/categorias/${categoryName
                                                    .toLowerCase()}/${UseRemoveTildes(c.nombre.replace(/\s+/g, '-').replace(/\//g, '-'))}/${c.categoriaid}/${c.subcategoriaid}/1`}
                                            >
                                              {c.nombre}
                                            </a>

                                        )
                                      }
                                    </div>
                                )}
                              </div>
                              :
                              <a
                                  className="rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
                                  href={`/productos/categorias/${c.name
                                      ?.replace(/\s+/g, '-').replace(/\//g, '-')
                                      .toLowerCase()}/${c.id}/1`}
                              >
                                {c.name}
                              </a>

                      )
                  }
                </div>
                {subCategories.length > 0 && (
                    <div className="w-[20%] max-md:hidden flex flex-col z-10 bg-white p-4 right-30 rounded border-1 border-gray-01 w-[100%]">
                      {
                          subCategories.length > 0 && subCategories.map((c) =>

                              <a
                                  className="rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
                                  href={`/productos/categorias/${categoryName
                                      .toLowerCase()}/${UseRemoveTildes(c.nombre.replace(/\s+/g, '-').replace(/\//g, '-'))}/${c.categoriaid}/${c.subcategoriaid}/1`}
                              >
                                {c.nombre}
                              </a>

                          )
                      }
                    </div>
                )}
              </div>
          )}

          {/* {openCategoriasResp && openCategorias === false &&(
        <div className="flex absolute w-[100%] top-[180px] max-md:visible left-0" >
          <div className="flex flex-col z-10 top-[120px] bg-white p-4 w-[100%]">
          {
            categories.length > 0 && categories.map((c) =>
              Object.values(c.subcategorias).length > 0 ?
              <div>
                <p
                  onClick={() => {
                    setOpenSubs(!openSubs)
                    handleGetSubCategories({categoria: c.name, subcategorias: Object.values(c.subcategorias)})
                  }}
                  className="flex items-center justify-between rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
                  >{c.name} <CaretDown/>
                </p>
                {categoryName === c.name && openSubs === true &&(
                  <div className="w-full flex flex-col z-10 bg-white p-4 right-30 rounded border-gray-01">
                    {
                      c.subcategorias.map((c) =>

                        <a
                          className="rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
                          href={`/productos/categorias/${categoryName
                            .toLowerCase()}/${UseRemoveTildes(c.nombre.replace(/\s+/g, '-').replace(/\//g, '-'))}/${c.categoriaid}/${c.subcategoriaid}/1`}
                        >
                          {c.nombre}
                        </a>

                      )
                    }
                  </div>
                )}

              </div>
              :
              <a
                className="rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
                href={`/productos/categorias/${c.name
                  ?.replace(/\s+/g, '-').replace(/\//g, '-')
                  .toLowerCase()}/${c.id}/1`}
              >
                {c.name}
              </a>


            )
          }
        </div>

      </div>
      )} */}

        </div>
  )}else{
    return (
        <div className={`bg-blue p-1 pt-0 text-[12px] h-10 ${menu_desplegable === "1" && 'h-14'}` }>
          <div className="flex gap-4 w-full mx-auto font-bold items-center">
            {
              menu_desplegable === "1" ?

                  <div className="flex items-center p-3">
                    <button
                        className="flex items-center rounded-[5px] p-1 hover:text-white hover:bg-black duration-200 text-[16px]"
                        onMouseOver={() => {
                          setOpenCategorias(!openCategorias)
                          if(!openCategorias === false){
                            setSubCategories([])
                          }
                        }}
                        onClick={() => {
                          setOpenCategorias(!openCategorias)
                        }}
                    >


                      <List size={20} style={{marginRight:10}}/> CATEGORIES

                    </button>
                    <CarouselCategory/>
                  </div> :

                  botones.map((boton, index) => {
                    if (boton.label === "MIS PEDIDOS") {
                      if (!user) return null;
                    }
                    if (boton.label === "TIENDAS") {
                      if (dataStore?.multisucursal === "0") return null;
                    }
                    return <ButtonLink key={index} to={boton.to} label={boton.label} />;
                  })

            }
          </div>

          {openCategorias && (
              <div className="flex absolute w-[100%] max-md:top-[190px] min-md:top-[140px] left-0"
                   onMouseLeave={() => {
                     setOpenCategorias(false)
                     setSubCategories([])
                   }}>
                <div className="max-md:w-[100%] w-[25%] flex flex-col z-10 bg-white p-4 rounded border-1 border-gray-01">
                  {
                      categories.length > 0 && categories.map((c) =>
                          Object.values(c.subcategorias).length > 0 ?

                              <div key={c.id}>
                                <p
                                    onClick={() => {
                                      setOpenSubs(!openSubs)
                                      handleGetSubCategories({categoria: c.name, subcategorias: Object.values(c.subcategorias)})
                                    }}
                                    onMouseOver={() => {
                                      setSubCategories([])
                                      handleGetSubCategories({categoria: c.name, subcategorias: Object.values(c.subcategorias)})
                                    }}
                                    className="flex items-center justify-between rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
                                >{c.name} <CaretRight/>
                                </p>
                                {categoryName === c.name && openSubs === true &&(
                                    <div className="min-md:hidden w-full flex flex-col z-10 bg-white p-2 right-30 rounded border-gray-01">
                                      {
                                        c.subcategorias.map((c) =>

                                            <a
                                                className="rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
                                                href={`/productos/categorias/${categoryName
                                                    .toLowerCase()}/${UseRemoveTildes(c.nombre.replace(/\s+/g, '-').replace(/\//g, '-'))}/${c.categoriaid}/${c.subcategoriaid}/1`}
                                            >
                                              {c.nombre}
                                            </a>

                                        )
                                      }
                                    </div>
                                )}
                              </div>
                              :
                              <a
                                  className="rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
                                  href={`/productos/categorias/${c.name
                                      ?.replace(/\s+/g, '-').replace(/\//g, '-')
                                      .toLowerCase()}/${c.id}/1`}
                              >
                                {c.name}
                              </a>

                      )
                  }
                </div>
                {subCategories.length > 0 && (
                    <div className="w-[20%] max-md:hidden flex flex-col z-10 bg-white p-4 right-30 rounded border-1 border-gray-01 w-[100%]">
                      {
                          subCategories.length > 0 && subCategories.map((c) =>

                              <a
                                  className="rounded-[5px] text-black text-[14px] hover:bg-black hover:text-blue py-1 px-2 cursor-pointer"
                                  href={`/productos/categorias/${categoryName
                                      .toLowerCase()}/${UseRemoveTildes(c.nombre.replace(/\s+/g, '-').replace(/\//g, '-'))}/${c.categoriaid}/${c.subcategoriaid}/1`}
                              >
                                {c.nombre}
                              </a>

                          )
                      }
                    </div>
                )}
              </div>
          )}

        </div>
    )
  }
}
