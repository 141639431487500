import { useState, useEffect } from 'react'

import { Link, useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { Toaster, toast } from 'sonner'

import {
  Storefront,
  Truck,
  Handshake,
  ShoppingBag,
} from '@phosphor-icons/react'

import {
  invoiceClient,
  finalInvoice,
  saveDataClient,
  saveDirectClient,
  calculateInvoice,
  getTotalProducts,
  setGSPagoMP,
  cotizarEnvio,
  getPuntosDeRetiro,
  getProvincias,
  getPaises,
  getCiudades,
  agregarEnvio,
  getEnvioId,
  deleteProductCartMethod,
  guardarDatosPedidoADomicilio,
  guardarDatosPedidoAPunto,
  cotizarEnvioADomicilio,
  generarTransaccionEnvioDomicilio,
  generarTransaccionEnvioAPunto,
} from '../methods'

import {
  setTotalProducts,
  setPaymentDataMP,
} from './../actions/index'

import {ModalMapPoints} from './ModalMapPoints'

import { urlCore } from './../config'

import { ModalMaintenance } from './ModalMaintenance'
import { Ring } from '@uiball/loaders'

export default function Checkout() {
  let dispatch = useDispatch()
  let navigate = useNavigate()

  let user = useSelector((state) => state.user)
  let invoiceId = useSelector((state) => state.invoiceId)
  let invoiceData = useSelector((state) => state.invoiceData)
  let dataStore = useSelector((state) => state.dataStore)
  let dataStore2 = useSelector((state) => state.dataStore2)
  let clientSession = useSelector((state) => state.clientSession)
  let enMantenimiento = dataStore?.default_mantenimiento  

  const [newInvoice, setNewInvoice] = useState()
  const [paises, setPaises] = useState([])
  const [provincias, setProvincias] = useState([])
  const [localidades, setLocalidades] = useState([])
  const [infoEnvioDomicilio, setInfoEnvioDomicilio] = useState('')
  const [infoEnvioPunto, setInfoEnvioPunto] = useState('')
  const [puntos, setPuntos] = useState([])
  const [punto, setPunto] = useState('')
  const [puntoId, setPuntoId] = useState('')
  const [precio, setPrecio] = useState(invoiceData)
  const [cp, setCp] = useState('')
  const [dataPickit, setDataPickit] = useState('')

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
      setShow(true);
  }

  const [error, setError] = useState(false)

  const [dataCheckout, setDataCheckout] = useState({
    contactData: {},
    shippingMethod: 1,
    paymentMethod: 1,
  })

  const [tipoEnvio, setTipoEnvio] = useState({
    envioDomicilio: false,
    envioPunto: false
  })

  const [envioFacturaDetalleId, setEnvioFacturaDetalleId] = useState('')

  async function handleCheckShippingMethods(method) {
    setDataCheckout({ ...dataCheckout, shippingMethod: method })
    if(envioFacturaDetalleId !== ''){
      let datos = await deleteProductCartMethod(user.id, envioFacturaDetalleId,invoiceId)
      if(datos.success){
        setEnvioFacturaDetalleId('')
        let sessionId = user ? user.id : clientSession
        let aux2 = await calculateInvoice(sessionId, invoiceId)
        setPrecio(aux2)
        toast(
          <div className="flex flex-col gap-2 text-[16px]">
            <p>Se quit&oacute; el valor del env&iacute;o del monto total</p>
          </div>
        )
      }
    }
  }


  async function handleGuardarEnvioPunto() {
    if(envioFacturaDetalleId === ''){
      await agregarEnvio(user.id, invoiceId, infoEnvioPunto.price, infoEnvioPunto.tax, infoEnvioPunto.totalPrice)
      let sessionId = user ? user.id : clientSession
      let aux2 = await calculateInvoice(sessionId, invoiceId)
      setPrecio(aux2)
      let aux3 = await getEnvioId(sessionId)
      setEnvioFacturaDetalleId(aux3)
      toast(
        <div className="flex flex-col gap-2 text-[16px]">
          <p>Se actualiz&oacute; el monto total</p>
        </div>
      )
    }else{
      await deleteProductCartMethod(user.id, envioFacturaDetalleId, invoiceId)
      await agregarEnvio(user.id, invoiceId, infoEnvioPunto.price, infoEnvioPunto.tax, infoEnvioPunto.totalPrice)
      let sessionId = user ? user.id : clientSession
      let aux2 = await calculateInvoice(sessionId, invoiceId)
      setPrecio(aux2)
      let aux3 = await getEnvioId(sessionId)
      setEnvioFacturaDetalleId(aux3)
      toast(
        <div className="flex flex-col gap-2 text-[16px]">
          <p>Se actualiz&oacute; el monto total</p>
        </div>
      )
    }
  }

  async function handleGuardarEnvioDomicilio() {
    if(envioFacturaDetalleId === ''){
      await agregarEnvio(user.id, invoiceId, infoEnvioDomicilio.price, infoEnvioDomicilio.tax, infoEnvioDomicilio.totalPrice)
      let sessionId = user ? user.id : clientSession
      let aux2 = await calculateInvoice(sessionId, invoiceId)
      setPrecio(aux2)
      let aux3 = await getEnvioId(sessionId)
      setEnvioFacturaDetalleId(aux3)
      toast(
        <div className="flex flex-col gap-2 text-[16px]">
          <p>Se actualiz&oacute; el monto total</p>
        </div>
      )
    }else{
      await deleteProductCartMethod(user.id, envioFacturaDetalleId, invoiceId)
      await agregarEnvio(user.id, invoiceId, infoEnvioDomicilio.price, infoEnvioDomicilio.tax, infoEnvioDomicilio.totalPrice)
      let sessionId = user ? user.id : clientSession
      let aux2 = await calculateInvoice(sessionId, invoiceId)
      setPrecio(aux2)
      let aux3 = await getEnvioId(sessionId)
      setEnvioFacturaDetalleId(aux3)
      toast(
        <div className="flex flex-col gap-2 text-[16px]">
          <p>Se actualiz&oacute; el monto total</p>
        </div>
      )
    }
  }

  const [loading, setLoading] = useState(false)

  async function cotizar() {
    if(dataPickit.address === '' || dataPickit.streetNumber === '' || dataPickit.city === '' ||
      dataPickit.province === '' || dataPickit.postalCode === '' || dataPickit.lastName === '' ||
      dataPickit.cuit === null 
    ){
      toast(
        <div className="flex flex-col gap-2 text-[18px]">
          <p>Debe completar los datos del perfil.<br/>
          <button className="bg-black border-white text-white rounded-[5px] my-1 px-2 hover:text-blue duration-200">
            <Link to="/editar-perfil">Ir a Editar Perfil</Link>
          </button></p>
        </div>
      )
    }else{
      let aux = await getPuntosDeRetiro(cp)
      setLoading(aux.loading)
      if(aux.data.length === 0){
        toast(
          <div className="flex flex-col gap-2 text-[16px]">
            <p>{aux.message}</p>
          </div>
        )
        let infoenvioadomicilio = await cotizarEnvioADomicilio(
          dataPickit
        )
        setInfoEnvioDomicilio(infoenvioadomicilio)
      }else{
        let infoenviopunto = await cotizarEnvio(
          dataPickit,
          aux[0].id, 
        )
        let infoenvioadomicilio = await cotizarEnvioADomicilio(
          dataPickit
        )
        setPuntos(aux)
        setPunto(aux[0])
        setInfoEnvioPunto(infoenviopunto)
        setInfoEnvioDomicilio(infoenvioadomicilio)
      }
    }
  }

  async function getInfoDireccion(){
    let auxPaises = await getPaises()
    setPaises(auxPaises)
    let auxProvincias = await getProvincias('40')
    setProvincias(auxProvincias)
    let auxLocalidades = await getCiudades('40', dataPickit.provinceid)
    setLocalidades(auxLocalidades)
  }

  async function cotizarDeNuevo(p){
    await cotizarEnvio(
      dataPickit,
      parseInt(p) 
    )
  }

  async function handleFinalPurchase() {

    await saveDataClient(dataCheckout.contactData)
    await saveDirectClient(dataCheckout.contactData)

    let finalPurchaseData = await finalInvoice(
      user.id,
      dataCheckout.shippingMethod,
      dataCheckout.paymentMethod,
      invoiceId
    )

    if(dataCheckout.shippingMethod === 3){
      if(tipoEnvio.envioDomicilio){
        await generarTransaccionEnvioDomicilio()
        await guardarDatosPedidoADomicilio(dataPickit)
      }else{
        await generarTransaccionEnvioAPunto()
        await guardarDatosPedidoAPunto(dataPickit,punto)
      }
    }
    if (finalPurchaseData.success) {
      toast(
        <div className="flex flex-col gap-2 text-[16px]">
          <p>Su orden ha sido confirmada</p>
        </div>
      )
      // await savePayClient(
      //   user.id,
      //   invoiceId,
      //   invoiceData.total,
      //   dataCheckout.paymentMethod
      // )
      let sessionId = user ? user.id : clientSession
      dispatch(setTotalProducts(await getTotalProducts(sessionId)))
      // await deleteProducts(sessionId, finalPurchaseData.facturaid)
      // dispatch(setTotalProducts(await getTotalProducts(sessionId)))
      // dispatch(setCalculateInvoice(await calculateInvoice(sessionId,invoiceId)))
      if (dataCheckout.paymentMethod == 2) {
        dispatch(
          setPaymentDataMP(
            await setGSPagoMP(
              finalPurchaseData.tiendaid,
              user.id,
              finalPurchaseData.pedidoid,
              finalPurchaseData.facturaid,
              dataStore2.url_mp,
              dataStore.api_key,
              urlCore,
              'mis-pedidos'
            )
          )
        )
      } else {
        navigate('/estado-compra/core/cart/')
      }
    }
  }

  useEffect(() => {
    ;(async () => {
      let newInvoiceId = await calculateInvoice(user.id,invoiceId)
      setNewInvoice(newInvoiceId.facturaid)
      let datos = await invoiceClient(user.id, newInvoiceId.facturaid)
      setDataCheckout({
        ...dataCheckout,
        contactData: {
          address: datos.address ,
          city: datos.city,
          cityid: datos.cityid === null ? "0" : datos.cityid,
          client:  datos.client,
          clienteid:  datos.clienteid,
          cuit:  datos.cuit,
          docType:  datos.docType,
          email:  datos.email,
          ivaType:  datos.ivaType,
          lastName:  datos.lastName,
          phone:  datos.phone,
          postalCode:  datos.postalCode === null ? "0" : datos.postalCode,
          province: datos.province,
          provinceid:  datos.provinceid === null ? "0" : datos.provinceid,
          streetNumber: datos.streetNumber ,
        },
      })
      setDataPickit(datos)
    })()
  }, [])

  return (
    <div className="w-[80vw] mx-auto my-12">
      <ModalMaintenance
        show={enMantenimiento}
      />
      {
        <ModalMapPoints
          show={show}
          handleClose={handleClose}
          points={puntos}
          point={punto}
          setPoint={setPunto}
          setPuntoId={setPuntoId}
          cotizarDeNuevo={cotizarDeNuevo}
        />
      }
      <Toaster />
      <p className="text-[13px]">
        INICIO / <span className="font-bold text-[16px]">CHECKOUT</span>
      </p>
      <div>
        <h2 className="text-[22px] border-b-1 border-black pb-2 mt-8">
          Datos de contacto
        </h2>
        <div className="flex gap-2 flex-col my-4">
          <label
            className="w-full flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue"
            htmlFor="email"
          >
            *EMAIL
            <input
              className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[15px] p-2 pl-4 focus:outline-0 focus-within:text-black"
              id="email"
              placeholder="ejemplo@ejemplo.com"
              type="email"
              name="email"
              value={dataCheckout.contactData.email}
              onChange={(e) =>
                setDataCheckout({
                  ...dataCheckout,
                  contactData: {
                    ...dataCheckout.contactData,
                    [e.target.name]: e.target.value,
                  },
                })
              }
            />
          </label>
          <label
            className="w-full flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue"
            htmlFor="phone"
          >
            *Teléfono
            <input
              className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[15px] p-2 pl-4 focus:outline-0 focus-within:text-black"
              id="phone"
              placeholder=""
              type="number"
              name="phone"
              value={dataCheckout.contactData.phone}
              onChange={(e) =>
                setDataCheckout({
                  ...dataCheckout,
                  contactData: {
                    ...dataCheckout.contactData,
                    [e.target.name]: e.target.value,
                  },
                })
              }
            />
          </label>
        </div>
        <div className="flex gap-2 flex-col my-4">
          <h2 className="text-[22px] border-b-1 border-black pb-2">
            Método de envío
          </h2>
          <div className="flex-col bg-gray-01 rounded-[5px] text-sm">
            {dataStore.default_retirolocal === '1' && (
              <div className="flex">
                <input
                  className="appearance-none peer/pickUp"
                  type="radio"
                  name="shipment"
                  value="1"
                  id="pickUp"
                  defaultChecked={dataCheckout.shippingMethod == 1}
                  onClick={()=>handleCheckShippingMethods(1)}
                />
                <label
                  htmlFor="pickUp"
                  className="flex items-center gap-2 w-full p-2 rounded-t-[5px] peer-checked/pickUp:text-blue peer-checked/pickUp:bg-black peer-checked/pickUp:font-bold hover:cursor-pointer hover:border-black hover:font-bold duration-200"
                >
                  <Storefront size={22} />
                  RETIRAR POR EL LOCAL
                </label>
              </div>
            )}
            {dataStore.default_enviocliente === '1' && (
              <div className="flex">
                <input
                  className="appearance-none peer/shipping"
                  type="radio"
                  name="shipment"
                  value="2"
                  id="shipping"
                  defaultChecked={dataCheckout.shippingMethod == 2}
                  onClick={()=>handleCheckShippingMethods(2)}
                />
                <label
                  htmlFor="shipping"
                  className="flex items-center gap-2 w-full p-2 rounded-b-[5px] peer-checked/shipping:text-blue peer-checked/shipping:bg-black peer-checked/shipping:font-bold hover:cursor-pointer hover:border-black hover:font-bold duration-200"
                >
                  <Truck size={22} />
                  ENVIAR A MI DOMICILIO
                </label>
              </div>
            )}
            {dataStore.default_pickit === '1' && (
              <div className="flex">
                <input
                  className="appearance-none peer/shipping"
                  type="radio"
                  name="shipment"
                  value="3"
                  id="pickit"
                  defaultChecked={dataCheckout.shippingMethod == 3}
                  onClick={()=>{
                    handleCheckShippingMethods(3)
                    getInfoDireccion()
                  }}
                />
                <label
                  htmlFor="pickit"
                  className="flex items-center gap-2 w-full p-2 rounded-b-[5px] peer-checked/shipping:text-blue peer-checked/shipping:bg-black peer-checked/shipping:font-bold hover:cursor-pointer hover:border-black hover:font-bold duration-200"
                >
                  <Truck size={22} />
                  PICKIT
                </label>
              </div>
            )}
          </div>
        </div>
        {dataCheckout.shippingMethod === 3 && (
          <div className="flex my-4 items-center">
            <div>
              <input
                className="p-2 w-[100%] border-1 rounded sm:w-[280px] lg:w-[380px] focus:outline-none"
                placeholder="Ingresá tu codigo postal"
                name="postalCode"
                value={cp}
                onChange={(e) =>
                  setCp(e.target.value)
                }
              />
              <button
                className="bg-black border-1 border-white text-white rounded-[5px] p-2 hover:text-blue duration-200"
                onClick={() => {
                  cotizar()
                  setLoading(true)
                }}
              >
                CALCULAR
              </button>
            </div>
            {
              loading &&
              <Ring
                className="text-black"
                size={30}
                lineWeight={5}
                speed={2}
                color="black"
              />
            }    
          </div>
          )
        }
        {
          infoEnvioDomicilio !== '' && infoEnvioPunto === '' ?
          <div className='flex w-full justify-center'>
          <button 
            className={`${tipoEnvio.envioDomicilio && 'bg-black text-blue'} w-1/4 mx-4 block max-w-sm p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`}
            onClick={() =>{
              setTipoEnvio({
                ...tipoEnvio,
                envioDomicilio: true,
                envioPunto: false
              })
              handleGuardarEnvioDomicilio()
            }}
          >
            <div className={`${tipoEnvio.envioDomicilio && 'border-b-blue'} flex justify-center items-center text-[16px] border-b-1 border-b-black mb-2`}>
                <h4 className='font-bold'>ENV&Iacute;O A DOMICILIO</h4>
            </div>
            <p><span className='font-bold'>Precio:</span> ${infoEnvioDomicilio.price+".00" || ''}</p>
            <p><span className='font-bold'>Impuestos:</span> {infoEnvioDomicilio.tax || ''}%</p>
            <p><span className='font-bold'>Total:</span> ${infoEnvioDomicilio.totalPrice+".00" || ''}</p>
          </button>
          </div> : null
        }
        {
          infoEnvioPunto !== '' && infoEnvioDomicilio !== '' && dataCheckout.shippingMethod === 3 ? (
            <div className='flex w-full justify-center'>
              <button 
                className={`${tipoEnvio.envioDomicilio && 'bg-black text-blue'} w-1/4 mx-4 block max-w-sm p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`}
                onClick={() =>{
                  setTipoEnvio({
                    ...tipoEnvio,
                    envioDomicilio: true,
                    envioPunto: false
                  })
                  handleGuardarEnvioDomicilio()
                }}
              >
                <div className={`${tipoEnvio.envioDomicilio && 'border-b-blue'} flex justify-center items-center text-[16px] border-b-1 border-b-black mb-2`}>
                    <h4 className='font-bold'>ENV&Iacute;O A DOMICILIO</h4>
                </div>
                <p><span className='font-bold'>Precio:</span> ${infoEnvioDomicilio.price+".00" || ''}</p>
                <p><span className='font-bold'>Impuestos:</span> {infoEnvioDomicilio.tax || ''}%</p>
                <p><span className='font-bold'>Total:</span> ${infoEnvioDomicilio.totalPrice+".00" || ''}</p>
              </button>
              <button 
                className={`${tipoEnvio.envioPunto && 'bg-black text-blue'} w-1/4 mx-4 block max-w-sm p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`}
                onClick={() =>{
                  setTipoEnvio({
                    ...tipoEnvio,
                    envioDomicilio: false,
                    envioPunto: true
                  })
                  handleGuardarEnvioPunto()
                }}
              >
                <div className={`${tipoEnvio.envioPunto && 'border-b-blue'} flex justify-center items-center text-[16px] border-b-1 border-b-black mb-2`}>
                  <h4 className='font-bold'>ENV&Iacute;O A PUNTO</h4>
                </div>
                <p><span className='font-bold'>Precio:</span> ${infoEnvioPunto.price+".00" || ''}</p>
                <p><span className='font-bold'>Impuestos:</span> {infoEnvioPunto.tax || ''}%</p>
                <p><span className='font-bold'>Total:</span> ${infoEnvioPunto.totalPrice+".00" || ''}</p>
              </button>
            </div>
          ) : null
        }
        {
          tipoEnvio.envioPunto && dataCheckout.shippingMethod === 3 ? (
          <div className="flex flex-col items-center mt-7">
            <div className="flex flex-col ">
              <button 
                className='mt-4 w-full bg-black p-2 text-sm text-white border-1 border-black hover:text-black hover:bg-white duration-200'
                onClick={handleShow}
                >SELECCIONAR EN EL MAPA
              </button>
              <h3 className='mt-4 mb-4 font-bold'>Punto seleccionado</h3>
              <label className='w-full mb-4 flex flex-col gap-2 text-[18px]' htmlFor='type'>
                <select 
                className='text-[16px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black' 
                id='puntoId' 
                name='puntoId' 
                value={puntoId}
                onChange={(e) =>{
                  setPuntoId(e.target.value)
                  let punto = puntos.filter((p) => p.id === parseInt(e.target.value))[0]
                  setPunto(punto)
                  cotizarDeNuevo(e.target.value)
                }}
                >
                  <option className='text-[16px]' value=''>SELECCIONE UNA OPCIÓN</option>
                  {
                    puntos && puntos.map((p) =>
                      <option className='text-[16px]' key={p.id} value={p.id}>{p.id} - {p.name} - {p.address}</option>
                    )
                  }
                </select>
              </label>
            </div>
          </div>
          ) : null
        }
        {
          tipoEnvio.envioDomicilio && dataCheckout.shippingMethod === 3 ? (
            <div className="flex flex-col my-4 items-center">
                <h2 className="text-[22px] pb-2 mt-4 ">
                    Datos para el envío a domicilio
                </h2>
              <div className="flex flex-wrap my-4 justify-center">
                <label
                  className="w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-3.5 my-3"
                  htmlFor="name"
                >
                  *Nombre
                  <input
                    className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[15px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="name"
                    placeholder="Juan"
                    name="name"
                    value={dataPickit.client}
                    onChange={(e) =>
                      setDataPickit({
                        ...dataPickit,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </label>
                <label
                  className="w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-3.5 my-3"
                  htmlFor="lastName"
                >
                  *Apellido
                  <input
                    className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[15px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="lastName"
                    placeholder="Gonzalez"
                    name="lastName"
                    value={dataPickit.lastName}
                    onChange={(e) =>
                      setDataPickit({
                        ...dataPickit,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </label>
                <label
                  className="w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-3.5 my-3"
                  htmlFor="email"
                >
                  *Email
                  <input
                    className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[15px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="email"
                    placeholder="ejemplo@ejemplo.com"
                    type="email"
                    name="email"
                    value={dataPickit.email}
                    onChange={(e) =>
                      setDataPickit({
                        ...dataPickit,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </label>
                <label
                  className="w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-3.5 my-3"
                  htmlFor="phone"
                >
                  *Teléfono
                  <input
                    className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[15px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="phone"
                    placeholder="12345678"
                    type="number"
                    name="phone"
                    value={dataPickit.phone}
                    onChange={(e) =>
                      setDataPickit({
                        ...dataPickit,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </label>
                <label
                  className="w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-3.5 my-3"
                  htmlFor="address"
                >
                  *Calle
                  <input
                    className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[15px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="address"
                    placeholder="Scalabrini Ortiz"
                    type="text"
                    name="address"
                    value={dataPickit.address}
                    onChange={(e) =>{
                      const regex = /\d/
                      let aux = regex.test(e.target.value)
                      setError(!aux)
                      setDataPickit({
                        ...dataPickit,
                        [e.target.name]: e.target.value,
                      })
                    }}
                  />
                  {
                    error && <p className='text-error text-[11px]'>Debe ingresar solo n&uacute;meros.</p>
                  }
                </label>
                <label
                  className="w-40 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-3.5 my-3"
                  htmlFor="streetNumber"
                >
                  *Altura
                  <input
                    className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[15px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="streetNumber"
                    placeholder="1234"
                    type="text"
                    name="streetNumber"
                    value={dataPickit.streetNumber}
                    onChange={(e) =>
                      setDataPickit({
                        ...dataPickit,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </label>
                <label className="w-1/4 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-3.5 my-3"
                  htmlFor="province"
                >
                  *Provincia
                  <select className='text-gray-03 text-[13px] border-1 border-gray-03 rounded-[15px] p-2 pl-4 focus:outline-0 focus-within:text-black' id='province' name='province'
                    value={dataPickit.provinceid}
                    onChange={(e) =>
                      setDataPickit({
                        ...dataPickit,
                        [e.target.name]: e.target.value,
                      })
                    }>
                    <option className='text-[16px]' value=''>SELECCIONE UNA OPCIÓN</option>
                    {
                      provincias && provincias.map((p) =>
                        <option className='text-[16px]' key={p.provinciaid} value={p.provinciaid}>{p.nombre}</option>
                      )
                    }
                  </select>
                </label>
                <label className="w-1/4 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-3.5 my-3"
                  htmlFor="cityid"
                >
                  *Ciudad
                  <select className='text-gray-03 text-[13px] border-1 border-gray-03 rounded-[15px] p-2 pl-4 focus:outline-0 focus-within:text-black' 
                    id='cityid' 
                    name='cityid'
                    value={dataPickit.cityid}
                    onChange={(e) =>
                      setDataPickit({
                        ...dataPickit,
                        [e.target.name]: e.target.value,
                      })
                    }>
                    <option className='text-[16px]' value=''>SELECCIONE UNA OPCIÓN</option>
                    {
                      localidades && localidades.map((p) =>
                        <option className='text-[16px]' key={p.localidadid} value={p.localidadid}>{p.nombrelocalidad}</option>
                      )
                    }
                  </select>
                </label>
                <label
                  className="w-1/4 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-3.5 my-3"
                  htmlFor="postalCode"
                >
                  *C&oacute;digo postal
                  <input
                    className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[15px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="postalCode"
                    placeholder="1400"
                    type="text"
                    name="postalCode"
                    value={dataPickit.postalCode}
                    onChange={(e) =>
                      setDataPickit({
                        ...dataPickit,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
          </div>
          ) : null
        }
        <div className="flex gap-2 flex-col my-4">
          <h2 className="text-[22px] border-b-1 border-black pb-2">
            Método de pago
          </h2>
          <div className="flex-col bg-gray-01 rounded-[5px] text-sm">
            {dataStore.default_efectivo === '1' && (
              <div className="flex">
                <input
                  className="appearance-none peer/agreedWithSeller"
                  type="radio"
                  name="payment"
                  value="1"
                  id="agreedWithSeller"
                  defaultChecked={dataCheckout.paymentMethod == 1}
                  onClick={() =>
                    setDataCheckout({ ...dataCheckout, paymentMethod: 1 })
                  }
                />
                <label
                  htmlFor="agreedWithSeller"
                  className="flex items-center gap-2 w-full p-2 rounded-t-[5px] peer-checked/agreedWithSeller:text-blue peer-checked/agreedWithSeller:bg-black peer-checked/agreedWithSeller:font-bold hover:cursor-pointer hover:border-black hover:font-bold duration-200"
                >
                  <Handshake size={22} />
                  ACORDAR CON EL VENDEDOR
                </label>
              </div>
            )}
            {dataStore.default_mercadopago === '1' && (
              <div className="flex">
                <input
                  className="appearance-none peer/mercadoPago"
                  type="radio"
                  name="payment"
                  value="2"
                  id="mercadoPago"
                  defaultChecked={dataCheckout.paymentMethod == 2}
                  onClick={() =>
                    setDataCheckout({ ...dataCheckout, paymentMethod: 2 })
                  }
                />
                <label
                  htmlFor="mercadoPago"
                  className="flex items-center gap-2 w-full p-2 rounded-b-[5px] peer-checked/mercadoPago:text-blue peer-checked/mercadoPago:bg-black peer-checked/mercadoPago:font-bold hover:cursor-pointer hover:border-black hover:font-bold duration-200"
                >
                  <ShoppingBag size={22} />
                  PAGAR A TRAVÉS DE MERCADO PAGO
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* BUTTONS */}
      <p className="font-bold my-4">
        TOTAL: {precio.simbolo}
        {precio.total}
      </p>
      <ul className="flex flex-wrap justify-between gap-4 w-[60vw] mx-auto items-center mb-12">
        <li className="flex-grow">
          <button
            className="w-full p-2 bg-black text-blue text-[16px] hover:text-white duration-200"
            onClick={() => navigate('/')}
          >
            SEGUIR COMPRANDO
          </button>
        </li>
        <li className="flex-grow">
          <button
            className="w-full p-2 bg-black text-blue text-[16px] hover:text-white duration-200"
            onClick={() => handleFinalPurchase()}
          >
            FINALIZAR COMPRA
          </button>
        </li>
      </ul>
    </div>
  )
}
