import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { getCategories } from '../methods/index'

import { Ring } from '@uiball/loaders'

import BrandCategoryCard from './BrandCategoryCard'
import Pagination from './Pagination'

import { UseScrollToTop } from './../utilities/UseScrollToTop'
import { ModalMaintenance } from './ModalMaintenance'
import { useSelector } from 'react-redux'

import { pais } from './../config'
export default function Categories() {

  let dataStore = useSelector((state) => state.dataStore)
  let enMantenimiento = dataStore?.default_mantenimiento

  const { actualPage } = useParams()
  const [categories, setCategories] = useState([])

  const [page, setPage] = useState(actualPage)
  const [lastPage, setLastPage] = useState()

  useEffect(() => {
    ;(async () => {
      let { data, lastPage } = await getCategories(page)
      setLastPage(lastPage)
      setCategories(data)
    })()
    return () => {
      setCategories([])
    }
  }, [page])
    if(pais === 'AR'){
        return (
            <div className="sm:w-[80vw] mx-auto">
                <ModalMaintenance
                    show={enMantenimiento}
                />
                <UseScrollToTop />
                <p className="w-[80vw] mx-auto text-[13px] flex items-center gap-2 my-12">
                    INICIO / <span className="font-bold text-[16px]"> CATEGORÍAS</span>
                </p>
                {categories.length > 0 ? (
                    <div className="flex flex-col">
                        <div className="flex sm:gap-[5px] lg:gap-[10px] justify-start flex-wrap">
                            {categories.map((category) => (
                                <BrandCategoryCard
                                    key={category.id}
                                    data={category}
                                    type="categorias"
                                    actualPage={page}
                                />
                            ))}
                        </div>
                        <Pagination
                            url={'categorias'}
                            actualPage={page}
                            setPage={setPage}
                            lastPage={lastPage}
                        />
                    </div>
                ) : (
                    <div className="h-[60vh] flex justify-center items-center">
                        <Ring
                            className="text-black"
                            size={40}
                            lineWeight={5}
                            speed={2}
                            color="black"
                        />
                    </div>
                )}
            </div>
        )}else{
        return (
            <div className="sm:w-[80vw] mx-auto">
                <ModalMaintenance
                    show={enMantenimiento}
                />
                <UseScrollToTop />
                <p className="w-[80vw] mx-auto text-[13px] flex items-center gap-2 my-12">
                    HOME / <span className="font-bold text-[16px]"> CATEGORIES </span>
                </p>
                {categories.length > 0 ? (
                    <div className="flex flex-col">
                        <div className="flex sm:gap-[5px] lg:gap-[10px] justify-start flex-wrap">
                            {categories.map((category) => (
                                <BrandCategoryCard
                                    key={category.id}
                                    data={category}
                                    type="categorias"
                                    actualPage={page}
                                />
                            ))}
                        </div>
                        <Pagination
                            url={'categorias'}
                            actualPage={page}
                            setPage={setPage}
                            lastPage={lastPage}
                        />
                    </div>
                ) : (
                    <div className="h-[60vh] flex justify-center items-center">
                        <Ring
                            className="text-black"
                            size={40}
                            lineWeight={5}
                            speed={2}
                            color="black"
                        />
                    </div>
                )}
            </div>
        )
    }
}
