import { useEffect, useState, useRef } from 'react'

import { getMostSoldProducts } from '../methods/index'

import { CaretRight, CaretLeft } from '@phosphor-icons/react'

import ProductCard from './ProductCard'
import ProductMostSold from './ProductMostSold'
import { pais } from './../config'
export default function MostSoldProducts({ titles }){

    const sliderRef = useRef()
    const [mostSoldProducts, setMostSoldProducts] = useState([])
    const [whatIsShowing, setWhatIsShowing] = useState('')

    useEffect(() => {
        (async () => {
            const mostSold = await getMostSoldProducts()
            setMostSoldProducts(mostSold)
            setWhatIsShowing('más vendidos')
        })()
    }, [])

    const handleBackSlide = (e) => {
        sliderRef.current.scrollLeft -= sliderRef.current.clientWidth + 20;
    }

    const handleNextSlide = (e) => {
        sliderRef.current.scrollLeft += sliderRef.current.clientWidth + 20;
    }
    if(pais === 'AR'){
    return (
        <div className={`pb-12 ${mostSoldProducts.length === 0 && "hidden"}`}>
            <div className="flex justify-end w-max my-[25px] gap-2 bg-black py-2 px-8 pl-20 rounded-r-[15px] text-blue text-[16px]">
                <button
                >
                M&Aacute;S VENDIDOS
                </button>
            </div>
            <div className="relative sm:w-[80vw] mx-auto ">
            <CaretLeft
                className="bg-black p-[0.3rem] pl-0 w-[3em] sm:w-[2em] h-[2em] sm:p-1 rounded-r-[20px] sm:rounded-full text-blue absolute top-1/2 left-0 sm:left-[-15px] z-10 hover:cursor-pointer hover:text-black hover:bg-blue duration-200"
                onClick={handleBackSlide}
                size={40}
            />
            <div
                className="flex sm:gap-[5px] lg:gap-[10px] justify-start overflow-x-hidden py-1"
                ref={sliderRef}
            >
                    {mostSoldProducts?.map(p => <ProductMostSold key={p.id} product={p}/>)}
            </div>
            <CaretRight
                className="bg-black p-[0.3rem] pr-0 w-[3em] sm:w-[2em] h-[2em] sm:p-1 rounded-l-[20px] sm:rounded-full text-blue absolute top-1/2 right-0 sm:right-[-15px] z-10 hover:cursor-pointer hover:text-black hover:bg-blue duration-200"
                onClick={handleNextSlide}
                size={40}
            />
            </div>
        </div>
        // <div className='my-6'>
        //     <div className='flex justify-end w-max my-[25px] gap-2 bg-black py-2 px-8 pl-20 rounded-r-[15px] text-white text-[16px]'>
        //         {titles.map((t,i) => <button key={i} className={(t.toUpperCase() === whatIsShowing.toUpperCase()) ? 'text-blue' : ''} onClick={() => handleGetCards(t)}>{t.toUpperCase()}</button>)}
        //     </div>
        //     <div className='relative w-[80vw] mx-auto'>
        //         <button onClick={handleBackSlide}>
        //             <CaretLeft className='bg-black p-1 rounded-full text-blue absolute top-1/2 left-[-15px] z-0 hover:cursor-pointer hover:text-black hover:bg-blue duration-200' size={42} />
        //         </button>
        //         <div className='flex gap-[20px] overflow-x-scroll scroll scroll-smooth scrollbar-hide' ref={sliderRef}>
        //             {mostSoldProducts?.map(p => <ProductMostSold key={p.id} product={p}/>)}
        //         </div>
        //         <button onClick={handleNextSlide}>
        //             <CaretRight className='bg-black p-1 rounded-full text-blue absolute top-1/2 right-[-15px] z-0 hover:cursor-pointer hover:text-black hover:bg-blue duration-200' size={42} />
        //         </button>
        //     </div>
        // </div>
    )}else{
        return (
            <div className={`pb-12 ${mostSoldProducts.length === 0 && "hidden"}`}>
                <div className="flex justify-end w-max my-[25px] gap-2 bg-black py-2 px-8 pl-20 rounded-r-[15px] text-blue text-[16px]">
                    <button
                    >
                        Best Sellers
                    </button>
                </div>
                <div className="relative sm:w-[80vw] mx-auto ">
                    <CaretLeft
                        className="bg-black p-[0.3rem] pl-0 w-[3em] sm:w-[2em] h-[2em] sm:p-1 rounded-r-[20px] sm:rounded-full text-blue absolute top-1/2 left-0 sm:left-[-15px] z-10 hover:cursor-pointer hover:text-black hover:bg-blue duration-200"
                        onClick={handleBackSlide}
                        size={40}
                    />
                    <div
                        className="flex sm:gap-[5px] lg:gap-[10px] justify-start overflow-x-hidden py-1"
                        ref={sliderRef}
                    >
                        {mostSoldProducts?.map(p => <ProductMostSold key={p.id} product={p}/>)}
                    </div>
                    <CaretRight
                        className="bg-black p-[0.3rem] pr-0 w-[3em] sm:w-[2em] h-[2em] sm:p-1 rounded-l-[20px] sm:rounded-full text-blue absolute top-1/2 right-0 sm:right-[-15px] z-10 hover:cursor-pointer hover:text-black hover:bg-blue duration-200"
                        onClick={handleNextSlide}
                        size={40}
                    />
                </div>
            </div>

        )
    }
}
