import { Toaster, toast } from 'sonner'

export default function SignUpStore({user, setUser}){

    async function handleSignUp(){
        if(store.name === ''  || store.type === '' || store.dni === ''){
            toast(
                <div className="flex flex-col gap-2 text-[13px]">
                    <p>Debe llenar todos los campos por favor</p>
                </div>
            )
            return
        }
        if(store.dni.length < 8){
            toast(
                <div className="flex flex-col gap-2 text-[13px]">
                    <p>Revise que el DNI sea el correcto</p>
                </div>
            )
            return
        }
    }

    return(
        <>
            <Toaster />
            <p className="font-bold text-[16px]">DATOS PARA REGISTRAR LA TIENDA</p>
                <label className='w-full flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue' htmlFor='name'>NOMBRE DE LA TIENDA
                    <input className='text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black' id='name' type='text' name='name' value={user.store.name}
                    onChange={(e) =>
                        setUser({
                            ...user,
                            store:{
                                ...user.store,
                                [e.target.name]: e.target.value
                            }
                        })}
                    />
                </label>
                <label className='w-full flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue' htmlFor='type'>TIPO DE CONTRIBUYENTE<span className='text-blue text-sm'>(Si no emite comprobantes seleccione Consumidor Final)</span>
                    <select className='text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black' id='type' name='type' value={user.store.type}
                    onChange={(e) =>
                        setUser({
                            ...user,
                            store:{
                                ...user.store,
                                [e.target.name]: e.target.value
                            }
                        })}
                    >
                        <option value=''>SELECCIONE UNA OPCIÓN</option>
                        <option value='1'>IVA RESPONSABLE INSCRIPTO</option>
                        <option value='2'>IVA RESPONSABLE NO INSCRIPTO</option>
                        <option value='3'>IVA NO RESPONSABLE</option>
                        <option value='4'>IVA SUJETO EXENTO</option>
                        <option value='5'>CONSUMIDOR FINAL</option>
                        <option value='6'>RESPONSABLE MONOTRIBUTO</option>
                        <option value='7'>SUJETO NO CATEGORIZADO</option>
                        <option value='8'>PROVEEDOR DEL EXTERIOR</option>
                        <option value='9'>CLIENTE DEL EXTERIOR</option>
                        <option value='10'>IVA LIBERADO ? LEY Nº 19.640</option>
                        <option value='11'>IVA RESPONSABLE INSCRIPTO ? AGENTE DE PERCEPCIÓN</option>
                        <option value='12'>PEQUEÑO CONTRIBUYENTE EVENTUAL</option>
                        <option value='13'>MONOTRIBUTISTA SOCIAL</option>
                        <option value='14'>PEQUEÑO CONTRIBUYENTE EVENTUAL SOCIAL</option>
                    </select>
                </label>
                <label className='w-full flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue' htmlFor='dni'>CUIT / DNI
                    <input className='text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black' type='number' id='dni' name='dni' value={user.store.dni}
                    onChange={(e) =>
                        setUser({
                            ...user,
                            store:{
                                ...user.store,
                                [e.target.name]: e.target.value
                            }
                        })}
                    />
                </label>
        </>
    )
}
